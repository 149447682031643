.footer {
  background-color: $color-primary;
  margin-inline: 30px;
  margin-bottom: 30px;
  border-radius: 30px;
  overflow: hidden;
  margin-top: 50px;
  @include respond-to("max_tablet") {
    padding-inline: 20px;
    border-radius: 20px;
    margin-inline: 20px;
    margin-bottom: 20px;
  }
  &-top {
    .container {
      padding-block: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(#fff, .1);
      @include respond-to("max_tablet") {
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
      .heading {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1px;
        color: #fff;
        max-width: 450px;
        line-height: 1.3;
        @include respond-to("max_tablet") {
          margin-bottom: 15px;
        }
      }
    }
  }

  &-form {
    form {
      position: relative;
      input {
        background-color: #fff;
        height: 60px;
        width: 100%;
        border-radius: 999px;
        padding-left: 55px;
        padding-right: 80px;
        font-weight: 400;
        font-size: 14px;
        @include respond-to("min_desktop") {
          min-width: 500px;
        }
      }
      .icon {
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translateY(-50%);
        color: $color-text-light;
        font-size: 18px;
      }
      .send {
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translateY(-50%) rotate(-45deg);
        height: 50px;
        width: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 999px;
        background-color: $color-primary;
        color: #fff;
        z-index: 1;
        overflow: hidden;
        font-size: 18px;
        &:hover {
          &::before {
            transform: scale(0);
            opacity: 0;
          }
        }
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          background-color: $color-secondary;
          z-index: -1;
          transition: .4s;
          border-radius: 999px;
          opacity: 1;
        }
      }
    }
  }

  &-middle {
    .container {
      padding-block: 50px;
      border-bottom: 1px solid rgba(#fff, .1);
      @include respond-to("max_tablet") {
        text-align: center;
      }
    }
  }

  &-logo {
    a {
      display: block;
      position: relative;
    }
    h1 {
      font-size: 0;
      margin: 0;
    }
    img {
      width: 200px;
      filter: brightness(0) invert(1);
    }
  }

  &-info {
    margin-top: 20px;
    font-size: 14px;
    color: #fff;
  }

  &-social {
		display: flex;
		align-items: center;
		margin-top: 30px;
    @include respond-to("max_tablet") {
			justify-content: center;
		}
		.icon {
			font-size: 18px;
			color: #fff;
			background-color: rgba(#fff, .08);
			width: 40px;
			height: 40px;
			border-radius: 999px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .3s;
			&:not(:last-child) {
				margin-right: 10px;
			}
			&:hover {
				background-color: $color-secondary;
				color: #fff;
			}
		}
	}

  &-list {
    @include respond-to("max_tablet") {
			margin-top: 30px;
		}
    .heading {
      font-size: 22px;
      font-weight: 700;
      letter-spacing: -0.8px;
      color: #fff;
      margin-bottom: 20px;
    }
    ul {
      li {
        line-height: 1.3;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        > a {
          font-size: 16px;
          color: rgba(#fff, .8);
          line-height: 1.2;
          transition: .3s;
          &:hover {
            color: $color-secondary;
          }
        }
      }
    }
  }

  &-contact {
    display: flex;
    flex-direction: column;
    > a {
      display: inline-flex;
      align-items: center;
      color: #fff;
      transition: .3s;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:hover {
        color: $color-secondary;
      }
      .icon {
        font-size: 18px;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        @include respond-to("max_tablet") {
          display: none;
        }
      }

      .text {
        line-height: 1.2;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.5px;
        flex: 1;
      }
    }
  }

  &-bottom {
    .container {
      padding-block: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond-to("max_tablet") {
        flex-direction: column;
        text-align: center;
        justify-content: center;
      }
      .text {
        font-size: 14px;
        color: #fff;
        strong {
          color: #fff;
          font-weight: 800;
          font-size: 15px;
        }
        a {
          color: #fff;
        }
      }
    }
  }
}