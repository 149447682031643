/**
 * 1. Correct the border color in all browsers.
 * 2. Add the correct box sizing in Firefox.
 */

:where(hr) {
	border: thin solid; /* 1 */
	border-color: #555 #808080 #808080 #555; /* 1 */
	height: 0; /* 2 */
	box-sizing: content-box; /* 2 */
}

/**
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */

:where(pre) {
	overflow: auto; /* 3 */
	font-size: inherit; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Safari.
 */

:where(abbr[title]) {
	text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

:where(b, strong) {
	font-weight: 700;
}

/**
 * 2. Correct the odd `em` font sizing in all browsers.
 */

:where(code, kbd, samp) {
	font-size: inherit; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

:where(small) {
	font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

:where(audio, canvas, iframe, img, svg, video) {
	vertical-align: middle;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

:where(iframe) {
	border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

:where(svg:not([fill])) {
	fill: currentcolor;
}

/* Tabular data
 * ========================================================================== */

/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color inheritance in all Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */

:where(table) {
	border-color: #a0a0a0; /* 2 */
	border-collapse: collapse; /* 1 */
	text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

:where(button, input, select) {
	margin: 0;
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

:where(button, select) {
	text-transform: none;
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
	appearance: button;
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

:where(fieldset) {
	border: 0.0625rem solid #a0a0a0;
}

/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

:where(progress) {
	vertical-align: middle;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Change the resize direction in all browsers (opinionated).
 */

:where(textarea) {
	margin: 0; /* 1 */
	resize: vertical; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 */

:where([type="search" i]) {
	appearance: textfield; /* 1 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button {
	height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
/* stylelint-disable-next-line selector-no-vendor-prefix */
::placeholder,
::-webkit-input-placeholder {
	color: inherit;
	opacity: 0.54;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::file-selector-button,
::-webkit-file-upload-button {
	appearance: button; /* 1 */
	font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

:where(
		button,
		input:is([type="button" i], [type="color" i], [type="reset" i], [type="submit" i])
	)::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:where(
		button,
		input:is([type="button" i], [type="color" i], [type="reset" i], [type="submit" i])
	)::-moz-focusring {
	outline: 0.0625rem dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:where(:-moz-ui-invalid) {
	box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct styles in Safari.
 */

:where(dialog) {
	position: fixed;
	inset: 0;
	margin: auto;
	border: solid;
	padding: 1em;
	width: fit-content;
	height: fit-content;
	color: #000;
	background-color: #fff;
}

:where(dialog:not([open])) {
	display: none;
}

/*
 * Add the correct display in Safari.
 */

:where(details > summary:first-of-type) {
	display: list-item;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

:where([aria-busy="true" i]) {
	cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

:where([aria-controls]) {
	cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

:where([aria-disabled="true" i], [disabled]) {
	cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

:where([aria-hidden="false" i][hidden]) {
	display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
	position: absolute;
	clip: rect(0, 0, 0, 0);
}
