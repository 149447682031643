.nav-mobile {
	$nav-height-header: 70px;
	$nav-height-footer: 50px;
	$nav-gutter-h: 60px;
	$nav-gutter-h-mobile: 30px;
	
	position: fixed;
  background-color: rgba($color-primary, .55);
	@include pos-fixed;
	z-index: 11111;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	opacity: 0;
  visibility: hidden;
	will-change: opacity;
	backdrop-filter: blur(3px);
  transition: .3s;
	@include padding-h(10px);

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }

	&__wrapper {
		background: $bg;
		width: 750px;
		max-height: 85vh;
		height: 100%;
		border-radius: $border-radius-xxl $border-radius-xxl 0 0;
		opacity: 0;
    visibility: hidden;
    transform: translateY(10%);
    transition: .4s;
		will-change: opacity, transform;
		@include respond-to("max_tablet") {
			@include margin-h(auto);
		}
		@include respond-to("max_mobile") {
			max-width: 390px;
		}
		@include respond-to("mobile_l") {
			max-width: 600px;
		}
		&.is-visible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
	}

	&__header {
		position: relative;
		z-index: 2;
		&-body {
			justify-content: space-between;
			align-items: center;
			@include padding-h($nav-gutter-h);
			height: $nav-height-header;
			border-bottom: 1px solid rgba($color-text, .06);
			@include respond-to("max_mobile") {
				@include padding-h($nav-gutter-h-mobile);
			}
		}

		&-logo {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			a {
				display: block;
				position: relative;
				img {
					width: 85px;
					border-radius: $border-radius-lg;
					// filter: invert(1) brightness(0);
				}
			}
		}

		&-close {
			display: inline-flex;
			color: rgba($color-text-light, .7);
	
			.icon {
				font-size: 28px;
			}
		}
	
	}

	&__content {
		height: calc(100% - #{$nav-height-header + $nav-height-footer} );
		overflow-y: auto;

		> ul {
			@include padding-v(15px);
			&:not(:last-child) {
				border-bottom: 1px solid rgba($color-text, .06);
			}
		}

		&-list {
			> li {
				&.is-open {
					> a {
						background: #fff;
						.heading {
							.text {
								color: $color-secondary;
							}

							.icon {
								color: rgba($color-secondary, .3);
							}
						}
					}
				}
				> a {
					padding: 15px $nav-gutter-h;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: sticky;
					top: 0;
					@include respond-to("max_mobile") {
						@include padding-h($nav-gutter-h-mobile);
					}

					.icon {
						&.ui-plus {
							color: $color-secondary;
							font-size: 20px;
							.fa-secondary {
								opacity: 0;
							}
						}
					}

					.heading {
						display: inline-flex;
						align-items: center;

						.text {
							color: $color-primary;
							font-size: 18px;
							letter-spacing: -0.5px;
							font-weight: 700;
						}

						.icon {
							margin-right: 15px;
							font-size: 24px;
							color: rgba($color-text-light, .2);
						}
					}
				}
			}

		}

		&-other {
			@include padding-h($nav-gutter-h);
			@include respond-to("max_mobile") {
				@include padding-h($nav-gutter-h-mobile);
			}
		}
	}

	&__sub {
		display: none;
		background: rgba($color-text, .06);
		li {
			padding-left: 103px;
			@include respond-to("max_mobile") {
				padding-left: 52px;
			}
			&:not(:last-child) {
				a {
					border-bottom: 1px solid rgba($color-text, .06);
				}
			}
			a {
				color: $color-text-light;
				font-size: 13px;
				font-weight: 600;
				display: block;
				padding: 9px 5px;
			}
		}
	}

	&__footer {
		@include padding-h($nav-gutter-h);
		height: $nav-height-footer;
		border-top: 1px solid rgba($color-text, .06);
		align-items: center;
		justify-content: space-between;
		background-color: rgba($color-primary, 1);
		@include respond-to("max_mobile") {
			@include padding-h($nav-gutter-h-mobile);
		}
		a {
			display: inline-flex;
			color: #fff;
			font-size: 14px;
			&:not(:last-child) {
				margin-right: 20px;
			}
		}
	}

}