.parallax {
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  @include respond-to("max_tablet") {
    border-radius: 25px;
  }

  &-cover {
    width: 100%;
    height: 700px;
    @include respond-to("max_tablet") {
			height: 600px;
		}
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  &-body {
    position: absolute;
    inset: 0;
    z-index: 1;
    padding: 100px;
    @include respond-to("max_tablet") {
			padding: 25px;
		}
    .section-header {
      .title {
        @include respond-to("max_tablet") {
          font-size: 32px;
        }
      }
      .text {
        max-width: 450px;
        @include respond-to("max_tablet") {
          display: none;
        }
      }
    }
  }

  &-app {
    width: 280px;
    position: absolute;
    right: 50px;
    bottom: -50px;
    transform: rotate(-20deg);
    z-index: -1;
    @include respond-to("max_tablet") {
			width: 150px;
      right: 0;
		}
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: rotateY(0);
      animation: app 4s running infinite;
      @keyframes app {
        0% { transform: rotateY(0); }
        50% { transform: rotateY(15deg); }
        100% { transform: rotateY(0); }
      }
    }
  }

  &-buttons {
    margin-top: 30px;
    margin-bottom: 50px;
    @include respond-to("max_tablet") {
			margin-bottom: 30px;
		}
    > a {
      &:not(:last-child) {
        margin-right: 10px;
      } 
    }
  }

  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 10px;
      li {
        background-color: #fff;
        height: 65px;
        border-radius: $border-radius-lg;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 6px;
        padding-right: 20px;
        flex: 0 0 auto;
        max-width: auto;
        &:not(:last-child) {
          margin-right: 15px;
          @include respond-to("max_tablet") {
            margin-right: 10px;
          }
        }

        @include respond-to("max_tablet") {
          height: 50px;
          padding-right: 5px;
          flex: 0 0 46.5%;
          max-width: 46.5%;
        }

        .icon {
          width: 50px;
          height: 50px;
          border-radius: $border-radius-lg;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-secondary;
          color: #fff;
          box-shadow: 0 0 10px rgba($color-dark, .1);
          font-size: 24px;
          @include respond-to("max_tablet") {
            font-size: 18px;
            width: 35px;
            height: 35px;
            border-radius: $border-radius-md;
          }
        }

        .title {
          font-size: 16px;
          font-weight: 700;
          color: $color-primary;
          flex: 1;
          padding-left: 10px;
          @include respond-to("max_tablet") {
            font-size: 13px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}