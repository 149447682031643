* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

html {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

body {
  // overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  display: block;
}

img {
  vertical-align: middle;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, figure, p, pre, fieldset, ul, ol, menu, form {
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}

input {
  border: 0 none;
  outline: none;
  box-shadow: none;
}

button {
  background: none;
  cursor: pointer;
}

button, fieldset, iframe {
  border: 0;
}


fieldset, ul, ol, button, menu {
  padding: 0;
}

ol, ul {
  list-style: none;
}

textarea {
  resize: vertical;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  padding: 0;
}

svg {
  width: 1em;
  height: 1em;
}

button {
  outline: 0;
}

.icon {
	font-size: 18px;
	color: currentColor;
	display: inline-flex;
	line-height: 1;
}