.loading {
	position: absolute;
	@include pos-fixed;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	transition: .3s;
	background: rgba(white, .7);
	
	&.ui-no-bg {
		background: transparent;
	}

	&:not([class*="ui-size-"]) {
		&::before {
			@include size(100px);
		}
	}
    
	&.ui-size-small {
		background: none;
		&::before {
			@include size(60px);
		}
  }
  
	&.is-visible {
		opacity: 1;
		visibility: visible;
  }
  
	&::before {
		content: "";
		position: absolute;
		border: 5px solid #fff;
		border-top: 5px solid $color-primary;
		border-radius: 50%;
		animation: spin .8s linear infinite;
	}
}