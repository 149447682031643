.swiper-buttons {
  @include respond-to("min_tablet") {
    --swiper-navigation-size: 42px;
  }
  @include respond-to("max_tablet") {
    --swiper-navigation-size: 28px;
  }

  bottom: 40px;
  position: absolute;
  right: 40px;
  z-index: 2;

  .swiper-button-prev, .swiper-button-next {
    color: #fff;
  }

  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    @include respond-to("min_tablet") {
      right: 40px;
    }
    @include respond-to("max_tablet") {
      right: calc(-1 * var(--swiper-navigation-size));
    }
  }

  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    @include respond-to("min_tablet") {
      left: 40px;
    }
    @include respond-to("max_tablet") {
      left: calc(-1 * var(--swiper-navigation-size));
    }
  }
}