.popup {
  position: fixed;
  @include pos-fixed;
  background-color: rgba($color-primary, .8);
  z-index: 2000;
  display: flex;
  @include padding-h($gutter);
  justify-content: center;
  transition: .3s;

  &:not(.is-visible) {
    opacity: 0;
    visibility: hidden;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(100%);
    transition: .3s;
  
    &:not(.is-visible) {
      opacity: 0;
      visibility: hidden;
      transform: translateY(100px);  
    }
  }

  &-content {
    width: 700px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    @include respond-to("max_desktop") {
      height: auto;
    }
    img {
      width: 100%;
    }

    .section-header {
      @include respond-to("desktop") {
        position: relative;
        top: 40px;
      }
    }

    .swiper {
      overflow: inherit;
      &-wrapper {
        display: flex;
        align-items: center;
      }

      &-slide {
        background-color: transparent !important;
      }
    }
  
    .swiper-button-next, .swiper-button-prev {
      background-color: rgba($color-secondary, 1);
      width: 50px;
      height: 50px;
      border-radius: $border-radius-lg;
      color: #fff;
      box-shadow: 0 5px 10px rgba($color-dark, .05);
      transition: .3s;
      @include respond-to("max_tablet") {
        margin-top: -30px !important;
      }
      &:hover {
        background-color: rgba(darken($color-secondary, 10), 1);
      }
  

  
      &::after {
        font-size: 22px;
      }
  
      &.swiper-button-disabled {
        opacity: .3;
      }
    }
  
    .swiper-button-prev {
      padding-right: 3px;
      left: -25px;
      @include respond-to("max_tablet") {
        left: -10px;
      }
    }
    .swiper-button-next {
      padding-left: 3px;
      right: -25px;
      @include respond-to("max_tablet") {
        right: -10px;
      }
    }
  }

  &-cover {
    width: 100%;
    height: auto;
    max-height: 600px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      object-fit: contain;
    }
  }

  &-close {
    position: absolute;
    @include pos-top-right(-15px, -15px);
    @include size(45px);
    border-radius: 999px;
    background: $color-secondary;
    font-size: 24px;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    cursor: pointer;
    @include respond-to("max_tablet") {
      top: -50px;
    }
    @include respond-to("desktop") {
      top: 50px;
    }
    &::before, &::after {
      content: "";
      position: absolute;
      background: #fff;
      height: 2px;
      width: 24px;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    &:hover {
      background: $color-secondary;
  
      &::before, &::after {
        background: #fff;
      }
    }
  }
}