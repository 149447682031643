.sidebar {

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 60px;

    &:not(.ui-no-border-bottom) {
      &::after {
        content: "";
        position: absolute;
        @include pos-bottom();
        background-image: radial-gradient(rgba(#fff, 1) 60%, transparent, transparent);
        height: 1px;
      }  
    }

    .title {
      font-size: 18px;
      line-height: 1.3;
      color: $color-text;
      font-weight: 700;
      letter-spacing: -0.5px;

      &:not([class*="ui-color-"]) {
        color: #fff;
      }

      &.ui-color-1 {
        color: $color-primary;
      }
    }
  }

  &-nav {
    background-color: rgba($color-secondary, .4);
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100%;
      background-image: linear-gradient($color-secondary, transparent);
      z-index: -1;
      opacity: .8;
    }

    .sidebar-header {
      margin-bottom: $gutter
    }

    &:not(:has( > .sidebar-header )) {
      padding: 30px 35px;
    }

    &:has( > .sidebar-header ) {
      padding-bottom: 30px;
      @include padding-h(35px);
    }

    border-radius: $border-radius-xl;

    ul {
      li {
        a {
          .icon {
            @include size(35px);
            font-size: 16px;
            border-radius: $border-radius-lg;
          }
          .heading {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
          }
        }
      }
    }

    ul {
      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        &:not(.is-current):hover, &.is-current {
          .icon {
            background-color: $color-primary;
            color: #fff;
          }
          .heading {
            color: lighten($color-primary, 10);
          }
        }
        a {
          display: flex;
          align-items: center;
          .icon {
            align-items: center;
            justify-content: center;
            background-color: rgba(#fff, .1);
            color: #fff;
            transition: .3s;
          }
          .heading {
            color: #fff;
            flex: 1;
            padding-left: 15px;
            padding-bottom: 5px;
            position: relative;
            transition: .3s;
            &::after {
              content: "";
              position: absolute;
              @include pos-bottom(-5px, 0, 0);
              background-image: radial-gradient(rgba(#fff, .3) 60%, transparent, transparent);
              height: 1px;
            }
          }
        }
      }
    }
  }

  &-item {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

}