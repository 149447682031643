$bg: #f5f6f7;

/* ____________________________________________ */
/* ________FONT__________ */
/* ____________________________________________ */
$font-primary: "Rubik", sans-serif;

/* ____________________________________________ */
/* ________GUTTER__________ */
/* ____________________________________________ */
$gutter-5: 5px;
$gutter-15: 15px;
$gutter: 20px;
$gutter-30: 30px;
$gutter-large: 50px;
$gutter-fluid: 50px;
$gutter-fluid-large: 120px;

/* ____________________________________________ */
/* ________NAV GUTTER__________ */
/* ____________________________________________ */
$nav-gutter: 12px;
$nav-gutter-small: 12px;
$nav-gutter-large: 18px;

/* ____________________________________________ */
/* ________COLOR__________ */
/* ____________________________________________ */
$color-primary: #1947a2;
$color-secondary: #e94e1b;
$color-third: #3baa36;
$color-fourth: #e71f73;
$color-fifth: #f39200;
$color-sixth: #a31b5b;
$color-seventh: #fedd00;
$color-text: #394857;
$color-text-light: #64707c;
$color-dark: #0c1f34;
$color-overlay: $color-dark;
$color-bg: #0E121A;

/* ____________________________________________ */
/* ________BORDER RADIUS__________ */
/* ____________________________________________ */
$border-radius-sm: 3px;
$border-radius: 5px;
$border-radius-md: 8px;
$border-radius-lg: 11px;
$border-radius-xl: 15px;
$border-radius-xxl: 20px;

/* ____________________________________________ */
/* ________INPUT FIELD__________ */
/* ____________________________________________ */
$field-height: 60px;

:root {
  /* CONTAINER */
  @include respond-to("min_laptop") {
    --container: 1250px;
  }

  @include respond-to("min_large") {
    --container: 1450px;
  }

  /* SLIDE */
  @include respond-to("min_tablet") {
    --slide-height: 800px;
  }
  @include respond-to("max_desktop") {
    --slide-height: 650px;
  }

  /* HEADER */
  @include respond-to("min_tablet") {
    --header-height: 120px;
  }
  @include respond-to("max_tablet") {
    --header-height: 100px;
  }

  /* HEADER STICKY */
  --header-height-sticky: 80px;
}