.blog {

  ul {
    row-gap: 40px;
    justify-content: center;
  }

  .swiper {
    padding-block: 50px;
    margin-block: -50px;
    padding-inline: 20px;
    margin-inline: -20px;
  }

  .swiper-button-next, .swiper-button-prev {
    background-color: rgba($color-secondary, 1);
    width: 50px;
    height: 50px;
    margin-top: -140px;
    border-radius: $border-radius-lg;
    color: #fff;
    box-shadow: 0 5px 10px rgba($color-dark, .05);
    transition: .3s;
    &:hover {
      background-color: rgba(darken($color-secondary, 10), 1);
    }

    &::after {
      font-size: 22px;
    }

    &.swiper-button-disabled {
      opacity: .3;
    }
  }

  .swiper-button-prev {
    padding-right: 3px;
    left: 0;
    @include respond-to("max_tablet") {
			left: 25px;
		}
  }
  .swiper-button-next {
    padding-left: 3px;
    right: 0;
    @include respond-to("max_tablet") {
			right: 25px;
		}
  }

  &-card {
    position: relative;
    &:hover {
      .blog-photo {
        box-shadow: 0 20px 20px rgba($color-dark, .2);
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &-photo {
    width: 100%;
    height: 280px;
    border-radius: $border-radius-xxl;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba($color-dark, .1);
    transition: .4s;
    @include respond-to("mobile_l") {
			height: 200px;
		}
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .6s;
    }
  }

  &-body {
    padding-top: 20px;
    height: 220px;
    .date {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.5px;
      color: $color-secondary;
      margin-bottom: 5px;
      .icon {
        font-size: 14px;
        margin-right: 7px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.7px;
      color: $color-text;
      margin-bottom: 10px;
      line-height: 1.3;
      @include line-clamp(2);
    }
    .text {
      font-size: 14px;
      color: $color-text-light;
      @include line-clamp(3);
    }

    .button-border {
      position: absolute;
      left: 0;
      bottom: 0;
    } 
  }
}