.sss {
  ul {
    li {
      box-shadow: 0 0 20px rgba($color-dark, .1);
      border-radius: $border-radius-lg;
      &.is-open {
        .icon {
          transform: rotate(90deg);
        }

        .sss-dropdown {
          display: block;
        }
      }

      &.is-first {
        .sss-dropdown {
          display: block;
        }
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  &-header {
    background-color: rgba($color-primary, .08);
    height: 60px;
    padding-inline: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius-lg;
    cursor: pointer;
    @include respond-to("max_desktop") {
      height: 100%;
      padding-block: 5px;
    }
    .heading {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.8;
      line-height: 1.3;
      color: $color-primary;
      flex: 1;
      @include line-clamp(3);
      @include respond-to("max_desktop") {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .icon {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      background-color: $color-primary;
      color: #fff;
      font-size: 16px;
      transition: .4s;
      margin-left: 20px;
    }
  }

  &-dropdown {
    padding: 20px;
    display: none;
    .text {
      font-size: 14px;
      color: $color-text-light;
    }
  }
}