// -------------------------------------
//   Form custom
// ------------------------------------- 
.form {
  $input-height: $field-height;

  > form {
    ul {
      li {
        margin-bottom: 15px;
        position: relative;
      }
    }
  }

  &-field {

    &.ui-selectbox {
      .form-error {
        right: 50px;
      }
    }

    &.ui-textarea {
      .form-error {
        top: 15px;
        transform: translateY(0);
      }
    }
      
    .heading {
      color: $color-primary;
      font-size: 13px;
      font-weight: 700;
    }

    .icon {
      &:not(.arrow) {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 55px;
        justify-content: center;
        color: $color-primary;
      }
    }

    &.is-error {
      input, textarea, label, select {
        border: 2px solid red !important;
      }
      .icon, label {
        color: red !important;
      }
    } 
    
    input, textarea {
      @include field( $color-text-light );

      &:focus {
        border: 1px solid $color-primary !important;
      }
    }
    
    textarea {
      padding-top: $gutter;
      height: 140px;
    }

    .icon.arrow {
      @include field-arrow;
    }
  }

  &-group {
    position: relative;
  }
  
  &-footer {
    @include padding-h(10px);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-kvkk {
    display: flex;
    flex-direction: column;
    .text {
      display: flex;
      @include respond-to("max_tablet") {
        flex-direction: column;
      }
      > a {
        font-weight: 700 !important;
      }
    }
  }

  &-send {
    > input {
      background: $color-primary;
      height: 60px;
      font-family: inherit;
      min-width: 200px;
      border: 0 none;
      text-align: center;
      border-radius: $border-radius-md;
      width: 100%;
      @include padding-h( $gutter );
      color: #fff;
      font-size: 16px;
      font-weight: 800;
      font-family: inherit;
      cursor: pointer;
      border: 2px solid transparent;
      transition: .3s;
      position: relative;
      will-change: transform;
      &:hover {
        background-color: $color-secondary;
      }
    }
  }
}

// -------------------------------------
//   Form validation
// ------------------------------------- 
.form {
  &-error {
    position: absolute;
    @include pos-top-right(50%, 25px);
    color: red;
    font-size: 12px;
    font-weight: 500;
    transform: translateY(-50%);
  }

  &-messages {
    display: flex;
    border-radius: 8px;
    padding: 25px 30px;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;

    &.ui-success {
      color: #3c763d;
      background-color: #dff0d8;
      border: 1px solid #d6e9c6;
    }
    
    &.ui-error {
      color: #a94442;
      background-color: #f2dede;
      border: 1px solid #ebccd1;
    }
  }
}