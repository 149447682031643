.scrolltop {
	display: inline-flex;
	justify-content: center;
	align-items: center;
  position: fixed;
	background: $color-secondary;
	border-radius: $border-radius-lg;
  text-align: center;
  color: #fff;
  z-index: 2002;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: .3s;

  @include respond-to("min_tablet") {
    @include pos-bottom-right(22px, 40px);
    @include size(50px);
  }
  
	@include respond-to("max_tablet") {
    @include size(50px);
    @include pos-bottom-right(15px, 40px);
  }
  
  .icon {
    @include respond-to("min_tablet") {
      font-size: 20px;
    }
		@include respond-to("max_tablet") {
			font-size: 16px;
		}
  }
  
  &.is-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}