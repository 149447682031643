.offer-detail {

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color-secondary, .15);
    padding: 20px 30px;
    border-radius: $border-radius-xxl;
    margin-bottom: 30px;
    @include respond-to("max_mobile") {
      padding: 15px;
    }
  }

  &__logo {
    a {
      display: block;
      position: relative;
    }
    h1 {
      font-size: 0;
      margin: 0;
    }
    img {
      width: 100px;
    }
  }

  &__steps {
    max-width: 550px;
    width: 100%;

    &.ui-step-2 {
      ul {
        &::after {
          width: 45%;
        }
      }
    }

    &.ui-step-3 {
      ul {
        &::after {
          width: 75%;
        }
      }
    }

    &.ui-step-4 {
      ul {
        &::after {
          width: 85%;
        }
      }
    }

    &:not([class *= "ui-step-"]) {
      ul {
        &::before, &::after {
          left: 0;
        }
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        left: 30px;
        right: 30px;
        top: 35px;
        height: 7px;
        z-index: -1;
        border-radius: 999px;
        background-color: $color-secondary;
        opacity: .1;
        @include respond-to("max_mobile") {
          top: 20px;
        }
      }
      &::after {
        content: "";
        position: absolute;
        left: 30px;
        right: 30px;
        width: 20%;
        top: 35px;
        height: 7px;
        z-index: -1;
        border-radius: 999px;
        background-color: $color-secondary;
        @include respond-to("max_mobile") {
          top: 20px;
        }
      }
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 100px;

        &.is-active {
          justify-content: flex-end;
          .number {
            background-color: $color-secondary;
            color: #fff;
          }
          .title {
            color: $color-dark;
          }
        }

        .step-car {
          height: 200px;
          width: 200px;
          border-radius: 999px;
          pointer-events: none;
          position: absolute;
          bottom: -20px;
          @include respond-to("max_mobile") {
            width: 150px;
            height: 150px;
            bottom: 15px;
          }

          canvas {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }


          &__station {
            width: 70px;
            height: 70px;
            border-radius: 999px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            &:not([class *= "ui-color-"]) {
              color: $color-primary;
              background-color: #fff;
            }

            &.ui-color-1 {
              color: #fff;
              background-color: $color-third;
            }

            @include respond-to("max_mobile") {
              width: 40px;
              height: 40px;
              font-size: 18px;
            }
          }
        }

        .title {
          font-size: 13px;
          font-weight: 700;
          letter-spacing: -0.3px;
          color: rgba($color-dark, .5);
          @include respond-to("max_mobile") {
            text-align: center;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    > .row {
      width: 100%;
    }
    
    .heading {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -1px;
      color: $color-primary;
      line-height: 1.3;
      margin-bottom: 30px;

      &.ui-txt-center {
        text-align: center;
      }
    }

    .slide-shape {
      width: 285px;
      left: 50%;
      transform: translateX(-50%);
      @include respond-to("max_desktop") {
        position: relative !important;
        bottom: 100px;
      }
    }
    
    .section-header {
      .icon {
        width: 130px;
        height: 130px;
        border-radius: 999px;
        background-color: $color-third;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 52px;
        color: #fff;
        margin-top: 30px;
      }
    }
  }

  &__form {
    max-width: 600px;
    background-color: #fff;
    padding: 40px 50px;
    border-radius: $border-radius-xxl;
    box-shadow: 0 0 20px rgba($color-dark, .1);
    @include respond-to("max_mobile") {
      padding: 30px;
    }
    .form {
      &-field {
        > input {
          color: $color-dark;
          font-size: 14px;
          font-weight: 400;
          padding-inline: 20px;
          @include respond-to("max_mobile") {
            height: 50px;
          }
          &::placeholder {
            color: $color-dark;
            opacity: 1;
          }
        }
      }

      ul {
        li {
          .title {
            font-size: 16px;
            font-weight: 700;
            color: $color-dark;
            letter-spacing: -0.5px;
            margin-bottom: 5px;
          }
          .text {
            font-size: 14px;
            color: rgba($color-text-light, .7);
            > a {
              display: inline-block;
              color: $color-primary;
              font-weight: 500;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  &__cover {
    width: 100%;
    height: 300px;
    border-radius: $border-radius-xl;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__price {
    box-shadow: 0 0 20px rgba($color-dark, .1);
    background-color: #fff;
    padding: 30px;
    border-radius: $border-radius-xxl;
    @include respond-to("max_tablet") {
      margin-bottom: 30px;
    }
    .price {
      font-size: 42px;
      font-weight: 700;
      color: $color-secondary;
      letter-spacing: -1px;
      line-height: 1;
      margin-bottom: 10px;
    }
    .text {
      font-size: 14px;
      color: $color-text;
      letter-spacing: -0.5px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .button {
      padding-inline: 30px;
      height: 45px;
    }

    &-information {
      padding-top: 40px;

      .title {
        font-size: 18px;
        font-weight: 700;
        color: $color-dark;
        margin-bottom: 10px;
      }

      ul {
        li {
          font-size: 14px;
          color: $color-text;
          line-height: 1.3;
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          .icon {
            font-size: 16px;
            color: $color-secondary;
            margin-right: 7px;
          }
        }
      }
    }
  }

  &__payment {
    &-left {
      box-shadow: 0 0 20px rgba($color-dark, .1);
      padding: 40px;
      border-radius: $border-radius-xl;
      @include respond-to("max_desktop") {
        margin-bottom: 30px;
      }
    }
    &-right {
      box-shadow: 0 0 20px rgba($color-dark, .1);
      padding: 40px;
      border-radius: $border-radius-xl;
      .heading {
        text-align: center;
        margin-bottom: 10px;
        font-size: 22px;
      }
      .price {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -1px;
        color: $color-secondary;
      }
      .text {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 10px;
      }
      .form-kvkk {
        .text {
          flex-direction: column;
          > a {
            display: inline-block;
          }
        }
      }
    }
  }
}