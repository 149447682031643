.referances {
  &-cover {
    width: 100%;
    height: 100px;
    &:hover {
      img {
        filter: grayscale(0);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      filter: grayscale(1);
      transition: .3s;
    }
  } 
}