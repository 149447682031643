// -------------------------------------
//   Scroll Controller
// -------------------------------------
.ui-no-scroll { overflow: hidden; }
.ui-no-scroll-y { overflow-y: hidden; }
.ui-no-scroll-x { overflow-x: hidden; }

// -------------------------------------
//   Position Default
// -------------------------------------
.ui-pos-relative {
  position: relative;
}
.ui-pos-absolute {
  position: absolute;
}
.ui-pos-inherit {
  position: inherit;
}
.ui-pos-sticky {
  position: sticky;
  top: 20px;
}

// -------------------------------------
//   Position Alignment
// -------------------------------------
.ui-text-center {
  text-align: center;
}
.ui-jc-center {
  justify-content: center;
}
.ui-jc-end {
  justify-content: flex-end;
}
.ui-jc-start {
  justify-content: flex-start;
}
.ui-jc-between {
  justify-content: space-between;
}

.ui-ai-center {
  align-items: center;
}
.ui-ai-end {
  align-items: flex-end;
}
.ui-ai-start {
  align-items: flex-start;
}
.ui-ai-between {
  align-items: space-between;
}

/* ui fx wrap */
.ui-fx-wrap {
	flex-wrap: wrap;
}

/* fx dir column */
.ui-fx-dir-column {
	flex-direction: column;
}

/* z-index */
.ui-z-index {
  position: relative;
  z-index: 9;
}

/* Semantically: Visible */
.ui-visually-hidden {
	@include visually-hidden;
}

/* Off-Screen */
.ui-offscreen {
	@include offscreen;
}

/* Button-Reset */
.ui-btn-reset {
	@include btn-reset;
}

// -------------------------------------
//   Hidden
// -------------------------------------
@include respond-to("min_desktop") {
  .ui-hidden { display: none; }  
}
@include respond-to("max_mobile") {
  .ui-xs-hidden { display: none }
}
@include respond-to("max_tablet") {
  .ui-sm-hidden { display: none }
}
@include respond-to("max_desktop") {
  .ui-md-hidden { display: none }
}

$values: 5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 65px, 70px, 75px, 80px, 85px, 90px, 95px, 100px;

// -------------------------------------
//   Padding
// -------------------------------------
@each $value in $values {
  .ui-pv-#{$value} {
    @include padding-v($value);
  }
}

.ui-pv-0 {
  @include padding-v(0 !important);
}

.ui-pt-0 { padding-top: 0 !important; }
.ui-pb-0 { padding-bottom: 0 !important; }

// -------------------------------------
//   Padding Top
// -------------------------------------
@each $value in $values {
  .ui-pt-#{$value} {
    padding-top: $value !important;
  }
}

// -------------------------------------
//   Padding Bottom
// -------------------------------------
@each $value in $values {
  .ui-pb-#{$value} {
    padding-bottom: $value !important;
  }
}

// -------------------------------------
//   Margin
// -------------------------------------
@each $value in $values {
  .ui-mv-#{$value} {
    @include margin-v($value !important);
  }
}

.ui-mv-0 {
  @include margin-v(0 !important);
}

.ui-mt-0 { margin-top: 0 !important; }
.ui-mb-0 { margin-bottom: 0 !important; }
.ui-ml-0 { margin-left: 0 !important; }
.ui-mr-0 { margin-right: 0 !important; }

// -------------------------------------
//   Margin Top
// -------------------------------------
@each $value in $values {
  .ui-mt-#{$value} {
    margin-top: $value !important;
  }
}

// -------------------------------------
//   Margin Bottom
// -------------------------------------
@each $value in $values {
  .ui-mb-#{$value} {
    margin-bottom: $value !important;
  }
}
