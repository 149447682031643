$article-width: 73%;
$article-width-tablet: 62%;
$article-width-desktop: 67%;

$sidebar-width: calc(100% - #{$article-width});
$sidebar-width-tablet: calc(100% - #{$article-width-tablet});
$sidebar-width-desktop: calc(100% - #{$article-width-desktop});

.inner-page {

  &__wrapper {
    &:not([class*="ui-mt-"]) {
      @include respond-to("min_tablet") {
        margin-top: 50px;
      }
      @include respond-to("max_tablet") {
        margin-top: 30px;
      }
    }
    &:not([class*="ui-mb-"]) {
      @include respond-to("min_tablet") {
        margin-bottom: 50px;
      }
      @include respond-to("max_tablet") {
        margin-bottom: 30px;
      }
    }

    &.ui-mv-0 {
      @include margin-v(0);
    }

    &.ui-style-gradient {
      position: relative;
      z-index: 1;
      padding-top: 50px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 300px;
        background-image: linear-gradient($color-primary, transparent);
        z-index: -1;
        opacity: .4;
      }
    }

    &.ui-bg-1 {
      background-color: rgba($color-secondary, .15);
      padding-block: 50px;
    }
  }

  &__row {
    &.ui-dir-row-reverse {
      @include respond-to("min_tablet") {
        flex-direction: row-reverse;
      }
      @include respond-to("max_tablet") {
        flex-direction: column-reverse;
      }
    }

    &:not(.ui-dir-row-reverse) {
      @include respond-to("max_tablet") {
        flex-direction: column;
      }
    }

    &:not([class*="ui-gutter-"]) {
      > div {
        @include respond-to("min_laptop") {
          @include padding-h(20px);
        }
        @include respond-to("max_laptop") {
          @include padding-h(10px);
        }
      }  
    }

    &.ui-gutter-15 {
      > div {
        @include respond-to("min_laptop") {
          @include padding-h(15px);
        }
        @include respond-to("max_laptop") {
          @include padding-h(10px);
        }
      }
    }
  }

  &__article {
    &:not([class*="col-"]) {
      @include respond-to("min_desktop") {
        flex: 0 0 $article-width;
        max-width: $article-width
      }
  
      @include respond-to("max_tablet") {
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include respond-to("tablet") {
        flex: 0 0 $article-width-tablet;
        max-width: $article-width-tablet;
      }
      @include respond-to("desktop") {
        flex: 0 0 $article-width-desktop;
        max-width: $article-width-desktop;
      }
    }
  }  

  &__sidebar {
    @include respond-to("max_tablet") {
      margin-top: 40px;
    }

    &:not([class*="col-"]) {
      > div {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
  
      @include respond-to("min_desktop") {
        flex: 0 0 $sidebar-width;
        max-width: $sidebar-width;
      }

      @include respond-to("max_tablet") {
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include respond-to("tablet") {
        flex: 0 0 $sidebar-width-tablet;
        max-width: $sidebar-width-tablet;
      }
      @include respond-to("desktop") {
        flex: 0 0 $sidebar-width-desktop;
        max-width: $sidebar-width-desktop;
      }
  
    }
  }

  &__content {
    display: inline-block;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 50px;
    }

    &:not(.ui-min-height) {
      &:not(.ui-no-height) {
        min-height: 100%;
      }  
    }

    &.ui-min-height {
      min-height: 700px;
    }

    &:not(.ui-no-bg) {
      background-color: #fff;
      border-radius: $border-radius-xl;
    }

    &.ui-bg-1 {
      background-color: #fff;
      border-radius: $border-radius-xxl;
      overflow: hidden;
      padding: 50px 40px;
      @include respond-to("max_tablet") {
        padding: 30px 20px;
      }
    }

    &.ui-offer-detail {
      background-color: #fff;
      border-radius: $border-radius-xxl;
      overflow: hidden;
      padding: 40px 50px;
      margin-top: -50px;
      position: relative;
      z-index: 1;
      @include respond-to("max_tablet") {
        padding: 30px 20px;
      }
    }

    &.ui-txt-center {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    > .row {
      &:nth-child(even) {
        @include respond-to("max_tablet") {
          flex-direction: column-reverse;
        }
      }
    }
  }
  
}