.header {
  height: var(--header-height);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1112;
  display: flex;
  align-items: center;
  @include respond-to("max_tablet") {
    background-color: #fff;
  }

  &-container {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: $border-radius-xl;
    padding-inline: 20px;
    overflow: hidden;
    position: relative;
    @include respond-to("max_tablet") {
			justify-content: center;
		}
  }

  .hamburger {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include respond-to("max_desktop") {
      display: flex;
    }
    .icon {
      color: rgba($color-primary, 1);
      font-size: 20px;
    }
  }

  &-logo {
    @include respond-to("tablet") {
			margin-left: 30px;
		}
    a {
      display: block;
      position: relative;
    }
    h1 {
      font-size: 0;
      margin: 0;
    }
    img {
      width: 160px;
      @include respond-to("max_mobile") {
        width: 120px;
      }
    }
  }

  &-button {
    @include respond-to("max_tablet") {
      display: none;
    }
    > a {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &__mobile {
      position: absolute;
      right: 15px;
      width: 35px;
      height: 35px;
      box-shadow: 0 0 20px rgba($color-dark, .1);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-secondary;
      color: #fff;
      border-radius: $border-radius-lg;
      font-size: 16px;
      @include respond-to("min_tablet") {
        display: none;
      }
    }
  }
}