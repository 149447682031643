.accordion {
  &:not(.ui-no-content) {
    > ul {
      > li {
        &:hover {
          .accordion {
            &-right {
              > .icon {
                transform: scale(1.2) translateX(3px) rotate(-90deg);
              }
            }
          }
        }
      }
    }
  }

  &.ui-no-content {
    > ul {
      > li {
        &:hover {
          .accordion {
            &-right {
              > .icon {
                transform: scale(1.2) translateX(3px);
              }
            }
          }
        }
      }
    }
  }

  > ul {
    > li {
      background: #fff;
      border: 1px solid rgba($color-text, .08);
      border-radius: $border-radius-md;
      margin-bottom: 15px;
      overflow: hidden;
      &:last-child {
        margin-bottom: 0;
      }

      &.is-open, &:hover {
        border: 1px solid transparent;
        box-shadow: 0 5px 5px rgba($color-text-light, .1);

        .accordion {
          &-header {
            background: $bg;
            .title {
              color: $color-dark;
            }
          }
        }
      }

      &.is-open {
        .accordion {
          &-right {
            > .icon {
              transform: rotate(-180deg);
            }
          }
        }
      }

    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px;
    cursor: pointer;
    transition: .3s;
    @include respond-to("max_mobile") {
      padding: 20px 17px;
    }
    @include respond-to("max_desktop") {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .title {
      color: $color-text;
      font-size: 16px;
      letter-spacing: -0.3px;
      font-weight: 600;
      line-height: 1.4;
      overflow: hidden;
      max-height: 43px;
      @include respond-to("max_mobile") {
        font-size: 14px;
      }
    }
  }

  &-right {
    margin-left: 30px;
    display: flex;
    align-items: center;
    @include respond-to("max_desktop") {
      margin-left: 0;
      margin-top: 5px;
    }
    > .icon {
      margin-top: 5px;
      color: $color-text-light;
      font-size: 13px;
      transition: all .4s cubic-bezier(0.215, 0.61, 0.335, 1);
      transition: .3s;
    }
    .date {
      display: inline-flex;
      align-items: center;
      color: rgba($color-text-light, .7);
      .icon {
        font-size: 12px;
        margin-right: 5px;
      }
      .text {
        font-size: 11px
      }
    }
  }

  &-content {
    @include padding-h(35px);
    display: none;

    .page-content__description {
      font-size: 14px;
      font-weight: 500;
      @include padding-v(30px);
    }
  }
}
