.overlay {
	position: fixed;
	@include pos-fixed;
  background: $color-overlay;
	@include size(100%);
	z-index: 999;
	opacity: 0;
	visibility: hidden;
  transition: .3s;
  
	&.is-visible {
		opacity: .6;
		visibility: visible;
	}

}