label.radio {
  display: inline-flex;
  cursor: pointer;

  &:not(.ui-no-align-center) {
    align-items: center;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  .radio {
    display: inline-flex;
    @include size(24px);
    background: #fff;
    border: 2px solid rgba($color-text, .1);
    border-radius: 50%;
    transition: .2s;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      @include pos-fixed(3px);
      background: $color-primary;
      border-radius: 50%;
      opacity: 0;
      visibility: hidden;
    }
  }

  .text {
    color: $color-text;
    flex: 1;
    font-size: 14px;
    line-height: 1.2;
    padding-left: 10px;
    span {
      color: rgba($color-text-light, .7);
      margin-left: 5px;
    }
  }
  
  input:checked + .radio:before {
    opacity: 1;
    visibility: visible;
  }
}