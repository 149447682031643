.products {

  ul {
    row-gap: 40px;
  }

  .swiper {
    padding-block: 50px;
    margin-block: -50px;
    padding-inline: 20px;
    margin-inline: -20px;
  }

  .swiper-button-next, .swiper-button-prev {
    background-color: rgba($color-primary, 1);
    width: 50px;
    height: 50px;
    border-radius: $border-radius-lg;
    color: #fff;
    box-shadow: 0 5px 10px rgba($color-dark, .05);
    transition: .3s;
    @include respond-to("max_tablet") {
			margin-top: -50px !important;
		}
    &:hover {
      background-color: rgba(darken($color-primary, 10), 1);
    }

    @include respond-to("max_tablet") {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }

    &::after {
      font-size: 22px;
    }

    &.swiper-button-disabled {
      opacity: .3;
    }
  }

  .swiper-button-prev {
    padding-right: 3px;
    left: 0;
    @include respond-to("max_tablet") {
			left: 25px;
		}
  }
  .swiper-button-next {
    padding-left: 3px;
    right: 0;
    @include respond-to("max_tablet") {
			right: 25px;
		}
  }

  &-card {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 20px rgba($color-dark, .1);
    border-radius: 35px;
    overflow: hidden;
    @include respond-to("max_tablet") {
			flex-direction: column;
		}
    &:hover {
      .products-cover {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &-cover {
    width: 300px;
    height: 350px;
    overflow: hidden;
    @include respond-to("max_mobile") {
			width: 100%;
      height: 250px;
		}
    @include respond-to("mobile_l") {
			width: 100%;
      height: 200px;
		}
    @include respond-to("desktop") {
      width: 200px;
      height: 250px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .6s;
    }
  }

  &-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    @include respond-to("max_tablet") {
			padding: 20px;
		}
    @include respond-to("desktop") {
      padding: 20px;
    }
    @include respond-to("laptop") {
      padding: 20px;
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.5px;
      color: $color-dark;
      line-height: 1.3;
      margin-bottom: 5px;
      @include line-clamp(2);
      @include respond-to("mobile_l") {
        font-size: 20px;
      }
    }
    .text {
      font-size: 14px;
      color: $color-text-light;
      @include line-clamp(3);
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    margin-top: 40px;
    @include respond-to("max_laptop") {
			margin-top: 20px;
		}
    
    > a {
      @include respond-to("mobile_l") {
        font-size: 13px !important;
        padding-inline: 15px !important;
      }
      @include respond-to("desktop") {
        font-size: 13px !important;
        padding-inline: 15px !important;
      }
      @include respond-to("laptop") {
        padding-inline: 15px !important;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}