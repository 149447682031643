.about {
  &-photos {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      inset: -10px;
      background-image: url("../images/shapes/4.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      animation: rotate 8s infinite running linear;
      @keyframes rotate {
        0% { transform: rotate(0); }
        100% { transform: rotate(360deg); }
      }

      @include respond-to("max_tablet") {
        inset: 0;
      }
    }

    .row {
      > div {
        @include respond-to("max_tablet") {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }

  &-photo {
    width: 100%;
    height: 350px;
    border-radius: $border-radius-xl;
    overflow: hidden;
    @include respond-to("max_tablet") {
			height: 150px;
		}
    @include respond-to("tablet") {
			height: 200px;
		}
    &.ui-mt-100px {
      @include respond-to("max_tablet") {
        margin-top: 0 !important;
      }
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .6s;
    }

    &.ui-size-small {
      width: 75%;
      height: 250px;
      margin-top: 30px;
      @include respond-to("max_tablet") {
        width: 100%;
        height: 150px;
      }
    }

    &.ui-pos-absolute {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 95%;
    }
  }


  &-exprience {
    background-color: #fff;
    box-shadow: 0 10px 20px rgba($color-dark, .1);
    text-align: center;
    height: 150px;
    padding-inline: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-lg;
    margin-top: 30px;
    width: 50%;
    float: right;
    @include respond-to("max_desktop") {
			width: 100%;
      margin-bottom: 30px;
		}
    .title {
      font-size: 32px;
      font-weight: 800;
      letter-spacing: -1px;
      color: $color-primary;
      line-height: 1.1;
      span {
        font-size: 28px;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }

  &-body {
    padding-top: 30px;
  }

  &-list {
    margin-top: 30px;
    @include respond-to("tablet") {
			display: none;
		}
    ul {
      li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        &:hover {
          .icon {
            background-color: $color-secondary;
            color: #fff;
          }
        }
        .icon {
          width: 50px;
          height: 50px;
          border-radius: $border-radius-lg;
          background-color: #fff;
          box-shadow: 0 10px 20px rgba($color-dark, .1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-secondary;
          font-size: 24px;
          transition: .3s;
        }
        .title {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: -0.5px;
          color: $color-text;
          flex: 1;
          padding-left: 20px;
        }
      }
    }
  }

  &-counter {
    margin-top: 30px;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      box-shadow: 0 0 20px rgba($color-dark, .1);
      border-radius: $border-radius-lg;
      padding: 20px 30px;
      @include respond-to("max_desktop") {
        padding: 15px;
      }
      li {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        &:not(:last-child) {
          border-right: 1px solid rgba($color-dark, .1);
          padding-right: 30px;
          @include respond-to("max_tablet") {
            padding-right: 20px;
          }
          @include respond-to("tablet") {
            padding-right: 10px;
          }
        }
        .top {
          display: flex;
          align-items: center;
        }
        .number, .extra {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-primary;
          font-size: 32px;
          font-weight: 700;
          letter-spacing: -1px;
          line-height: 1;
          @include respond-to("max_desktop") {
            font-size: 22px;
          }
        }
        .title {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: -0.5px;
          color: $color-text-light;
          padding-top: 10px;
          @include respond-to("max_desktop") {
            font-size: 13px;
          }
        }
      }
    }
  }


}