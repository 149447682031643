/* stylelint-disable declaration-no-important */
/* __________________Reset_____________________ */
/* elad.medium.com/normalize-css-or-css-reset-9d75175c5d1e */
/* https://www.youtube.com/shorts/2lyDv0wOQuQ */
/* About container -> https://habr.com/ru/post/463923/ */

/* ____________________________________________ */
/* _________________General____________________ */
/* ____________________________________________ */
/* all: unset; */
html {
	break-inside: avoid-column;
	box-sizing: border-box;
	background-repeat: no-repeat;
}

*,
*::before,
*::after {
	break-inside: inherit;
	box-sizing: inherit;
	background-repeat: inherit;
}

* {
	min-width: 0;
}

*::before,
*::after {
	vertical-align: inherit;
	text-decoration: inherit;
}

/* ____________________________________________ */
/* _______________Typography___________________ */
/* ____________________________________________ */
/* html */
:where(:root) {
	overflow-wrap: break-word;
	tab-size: 4;
	text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}

/* :root */
:where(html) {
	font-family: system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans",
		sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 1.5;
}

:where(code, kbd, samp, pre) {
	font-family: ui-monospace, "Menlo", "Consolas", "Roboto Mono", "Ubuntu Monospace", "Noto Mono",
		"Oxygen Mono", "Liberation Mono", monospace, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol", "Noto Color Emoji";
}

:where(body) {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizespeed;
}

:where(cite, address) {
	font-style: normal;
}

/* ____________________________________________ */
/* links */
:where(a) {
	display: inline-block;
	overflow-anchor: none;
	text-decoration: none;
	color: inherit;
	text-decoration-skip-ink: none;
	text-underline-offset: 0.25em;
}

/* ____________________________________________ */
/* paragraph */
:where(p) {
	margin: 0;
	text-wrap: balance;
}

/* ____________________________________________ */
/* headers */
:where(h1, h2, h3, h4, h5, h6) {
	margin: 0;
	font-weight: normal;
	line-height: 1.3;
	text-wrap: balance;
}

:where(h1) {
	font-size: 32px; // 32px
}

:where(h2) {
	font-size: 24px; // 24px
}

:where(h3, h4, h5, h6) {
	font-size: 18px; // 18.72px
}

/* ____________________________________________ */
/* add typography inheritance | input */
:where(button, input, select, textarea, optgroup, option),
::placeholder,
::file-selector-button {
	font: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	color: inherit;
}

/* ____________________________________________ */
/* images */
:where(img, svg, picture) {
	vertical-align: middle;
	object-fit: cover;
}

:where(
		img,
		svg,
		picture,
		iframe,
		input,
		video,
		audio,
		select,
		textarea,
		details,
		dialog,
		progress,
		canvas
	) {
	height: auto;
	max-width: 100%;
}

:where(figure) {
	margin: 0;
}

/* ____________________________________________ */
/* lists */
:where(ul, ol, dl) {
	list-style-type: none;
	list-style-position: inside;
	margin: 0;
	padding: 0;
}

::marker {
	color: inherit;
}

/* ____________________________________________ */
/* form cursor */
:where(form) {
	cursor: default;
}

/* form border */
:where(fieldset) {
	margin: 0;
	padding: 0;
}

/* btn reset */
:where(button, [type="buttton"]) {
	overflow-anchor: none;
	border-width: 0;
	padding: 0;
	background-color: transparent;
}

/* input reset */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-results-button,
[type="search"]::-webkit-search-decoration,
[type="search"]::-webkit-search-results-decoration {
	appearance: none;
}

[type="password"]::-ms-reveal {
	display: none;
}

[type="number"] {
	appearance: textfield;
}

[type="number"]::-webkit-inner-spin-button {
	appearance: none;
}

/* ____________________________________________ */
/* add cursor pointer */
:where(
		a,
		label,
		button,
		summary,
		[type="button"],
		[type="reset"],
		[type="submit"],
		[type="range"],
		[type="color"],
		[type="checkbox"],
		[type="radio"]
	),
dialog::backdrop,
[popover]::backdrop,
[type="file"]::file-selector-button,
[type="number"]::-webkit-inner-spin-button,
[type="search"]::-webkit-search-cancel-button,
[type="date"]::-webkit-calendar-picker-indicator {
	cursor: pointer;
}

/* ____________________________________________ */
/* _________________Outline____________________ */
/* ____________________________________________ */
/* keyboard Tabs */
:where(a, button, summary, select, textarea, input) {
	outline-width: 0;
	outline-color: transparent;
}

:where([type="file"]):focus {
	outline-width: 0;
}

:where(
		a,
		button,
		summary,
		[type="button"],
		[type="reset"],
		[type="submit"],
		[type="range"],
		[type="color"],
		[type="radio"],
		[type="checkbox"],
		[tabindex="0"]
	):focus-visible {
	outline: 0.125rem solid #4682b4;
}

:where(
		select,
		textarea,
		input:not(
				[type="button"],
				[type="reset"],
				[type="submit"],
				[type="range"],
				[type="color"],
				[type="radio"],
				[type="checkbox"],
				[type="file"]
			)
	):focus,
[type="file"]::file-selector-button:focus {
	outline: 0.125rem solid transparent;
}

/* outline-offset */
:where(
		summary,
		button,
		input:not([type="checkbox"], [type="radio"], [type="color"]),
		select,
		textarea
	),
[type="file"]::file-selector-button {
	outline-offset: 0.1875rem;
}

:where([type="checkbox"], [type="radio"], [tabindex="0"]) {
	outline-offset: 0.0625rem;
}
