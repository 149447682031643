.campaings {

  ul {
    width: 100%;
    justify-content: center;
    row-gap: 40px;
  }

  &-card {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 20px rgba($color-dark, .1);
    border-radius: $border-radius-xxl;
    overflow: hidden;
    &:hover {
      .campaings-cover {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &-cover {
    width: 100%;
    height: 350px;
    border-radius: $border-radius-xxl;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .6s;
    }
  }

  &-body {
    text-align: center;
    padding: 20px;
    height: 85px;
    .title {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.8px;
      line-height: 1.3;
      color: $color-text;
      @include line-clamp(2);
    }
  }
}