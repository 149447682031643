.account {
  .form {
    &-field {
      > input {
        &::placeholder {
          opacity: 1;
        }
      }

      .title {
        font-size: 13px;
        font-weight: 600;
        color: $color-secondary;
        opacity: 1;
        padding-left: 5px;
        margin-bottom: 5px;
      }
    }
  }

  &-policy {
    .row {
      row-gap: 20px;
    }
    &__box {
      border: 1px solid rgba($color-dark, .1);
      padding: 20px;
      border-radius: $border-radius-xl;
    }

    &__up {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 18px;
        font-weight: 700;
        color: $color-dark;
        letter-spacing: -0.5px;
      }
      .text {
        font-size: 14px;
      }
      .warring {
        font-size: 20px;
        color: $color-secondary;
        font-weight: 700;
        letter-spacing: -0.5px;
      }
    }
  }
}