html {
  @include respond-to("max_tablet") {
    overflow-x: hidden;
  }
}

body {
	font-family: $font-primary;
	font-size: 16px;
	line-height: 1.6;
	font-weight: 400;
	color: $color-text;
	letter-spacing: -0.2px;
	overflow-x: hidden;
	
	&.ui-bg {
		background: $bg;
	}

	&.is-scroll {
		overflow: hidden;
	}

  .ui-page {
    .header {
      background-color: #fff;
      position: relative;
    }
  }

}

.small-title {
  &:not(.ui-not) {
    position: relative;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.3;
    color: $color-secondary;
    font-weight: 600;
    letter-spacing: 4px;
    span {
      position: relative;
      svg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
        width: 100%;
        height: auto;
        path {
          fill: $color-secondary;
        }
      }
    }
  }
}

.section-header {
  &.ui-txt-center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.ui-txt-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-to("max_tablet") {
			flex-direction: column;
      justify-content: center;
      text-align: center;
      .button {
        margin-top: 30px;
      }
		}
  }

  &:has( > .small-title ) {
    .title {
      margin-top: 5px;
    }
  }

  &:not([class*="ui-txt-color-"]) {
    .title {
      color: $color-dark;

      span {
        color: $color-secondary;
      }  
    }
  }

  &.ui-txt-color-light {
    .title, .text {
      color: #fff;
    }
  }

  &:not([class*="ui-txt-size-"]) {
    .title {
      font-size: 52px;
      letter-spacing: -1.5px;
      @include respond-to("max_tablet") {
        font-size: 42px;
      }
      span {
        position: relative;
        svg {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -20px;
          width: 100%;
          height: auto;
          path {
            fill: $color-secondary;
          }
        }
      }
    }
  }

  &.ui-txt-size-large {
    .title {
      font-size: 54px;
      letter-spacing: -1.5px;
    }
  }
  
  &.ui-txt-size-small {
    .title {
      font-size: 28px;
      letter-spacing: -1px;
    }
  }

  &.ui-txt-size-medium {
    .title {
      font-size: 36px;
      letter-spacing: -1px;
    }
  }

  .title {
    line-height: 1.2;
    font-weight: 700;
  }

  > .text {
    line-height: 1.6;
    color: $color-text-light;

    &:not(.ui-no-mt) {
      margin-top: 5px;
    }

    &:not([class*="ui-txt-size-"]) {
      font-size: 16px;
    }

    &.ui-txt-size-small {
      font-size: 14px;
    }
  }
  
}