.how {
  ul {
    li {
      position: relative;
      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -5vw;
          top: 30px;
          background-image: url("../images/angle.png");
          width: 120px;
          height: 55px;
          background-repeat: no-repeat;
          opacity: 0.2;
          z-index: -1;
          @include respond-to("max_desktop") {
            display: none;
          }
        }

        @include respond-to("max_tablet") {
          margin-bottom: 30px;
        }
      }
      &:nth-child(odd) {
        margin-top: 50px;
        @include respond-to("max_tablet") {
          margin-top: 0;
        }
        &::after {
          top: 0;
        }
      }
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    &:hover {
      .icon {
        transform: scale(1.04);
        color: #fff;
        &::after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    .icon {
      display: inline-flex;
      position: relative;
      z-index: 1;
      width: 120px;
      height: 120px;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      z-index: 1;
      transition: .4s;
      font-size: 42px;
      color: $color-primary;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 120px;
        height: 120px;
        left: 0;
        border: 1px solid $color-primary;
        border-radius: 999px;
        opacity: 0.1;
      }
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: $color-primary;
        border-radius: 999px;
        transform: scale(0);
        transition: .4s;
        z-index: -1;
        opacity: .3;
      }
      span {
        position: absolute;
        right: -30px;
        top: 0;
        background: $color-primary;
        width: 30px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        border-radius: 999px;
        &::before {
          position: absolute;
          content: "";
          left: 3px;
          bottom: -3px;
          display: block;
          z-index: -1;
          width: 0;
          height: 0;
          border-left: 24px solid $color-primary;
          border-top: 15px solid transparent;
          border-bottom: 12px solid transparent;
          transform: rotate(21deg);
        }
      }
    }

    .title {
      font-size: 20px;
      line-height: 1.2;
      color: $color-text;
      font-weight: 600;
      letter-spacing: -0.5px;
      margin-bottom: 10px;
      @include respond-to("tablet") {
        font-size: 18px;
      }
    }
    .text {
      font-size: 14px;
      color: $color-text-light;
    }
  }
}