.buttons-container {
  &.ui-center {
    text-align: center;
    width: 100%;
  }
}

@mixin button {
  display: inline-flex;
  align-items: center;
  font-family: inherit;
  text-align: center;
  line-height: 1.2;
  outline: 0;
  letter-spacing: -.1px;
  font-weight: 700;
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: .3s;

  .icon {
    font-size: 16px;
    color: #fff;
    transition: .3s;
    &:not(.ui-pos-left) {
      margin-left: 17px;
    }
    &.ui-pos-left {
      margin-right: 13px;
      font-size: 18px;
    }
  }

  &:not([class*="ui-width-"]) {
    justify-content: center;
  }

  &.ui-width-min {
    min-width: 160px;
    justify-content: space-between;
  }

  &.ui-width-full {
    width: 100%;
  }

  &:not([class*="ui-size-"]) {
    @include padding-h(25px);
    height: 50px;
    font-size: 16px;
    border-radius: $border-radius-lg;
  }

  &.ui-size-small {
    @include padding-h(17px);
    height: 40px;
    font-size: 12px;
    border-radius: $border-radius-lg;
  }

  &:hover {
    .icon {
      &:not(.ui-pos-left) {
        transform: scale(1.1) translateX(5px);
      }
    }
  }

}

// -------------------------------------
//   Button
// -------------------------------------
.button {
  @include button;
  background: $color-primary;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    background-color: $color-secondary;
    z-index: -1;
    transition: .4s;
  }

  &:hover {
    &::before {
      top: inherit;
      bottom: 0;
      height: 100%;
      border-radius: 0;
    }
  }

  &.ui-color-light {
    background-color: #fff;
    color: $color-primary;

    .icon {
      color: currentColor;
    }
  }

  &.ui-color-wp {
    background: #25d366;

    .icon {
      color: #25d366;
    }
  }

  &.ui-color-1 {
    background: $color-secondary;
    &::before {
      background-color: $color-primary;
    }
  }

}

.button-border {
  @include button;
  border: 1px solid #fff;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    background-color: $color-primary;
    z-index: -1;
    transition: .4s;
  }

  &:hover {
    &::before {
      top: inherit;
      bottom: 0;
      height: 100%;
      border-radius: 0;
    }
  }

  &:not([class*="ui-color-"]) {
    &:hover {
      .icon {
        transform: scale(1.15);
      }
    }
  }

  &.ui-color-border-dark {
    color: $color-text-light;
    border: 1px solid rgba($color-text-light, .08);

    .icon {
      color: $color-text-light
    }
  }

  &.ui-color-1 {
    color: $color-primary;
    border: 1px solid $color-primary;
    &:hover {
      color: #fff;
    }

    .icon {
      color: $color-primary;
    }
  }

  &.ui-color-2 {
    color: $color-secondary;
    border: 1px solid $color-secondary;

    .icon {
      color: $color-secondary;
    }

    &:hover {
      background-color: $color-secondary;
      color: #fff;
      box-shadow: 0 5px 10px rgba($color-secondary, .4);

      .icon {
        color: #fff;
      }
    }
  }
}

// -------------------------------------
//   Button Line
// -------------------------------------
.button-line {
	display: inline-flex;
	align-items: center;
  color: $color-primary;
  font-size: 13px;
  font-weight: 600;
  position: relative;

  &:hover {
    .icon {
      transform: translateX(5px);
    }
  }
    
  .icon {
		margin-left: 10px;
		color: currentColor;
    font-size: 13px;
    transition: .3s;
	}

  &.ui-light {
    color: #fff;
  }

  &.ui-color-1 {
    color: $color-secondary;
  }

}

// -------------------------------------
//   Button Video
// -------------------------------------
.button-video {
  $circle-size: 72px;
  $circle-size-small: 55px;
  //
  display: inline-flex;
  align-items: center;

  &:not([class*="ui-color-"]) {
    .icon {
      border: 2px solid #fff;
      color: #fff;
    }
    .text {
      color: #fff;
    }
  }

  &.ui-color-2 {
    .icon {
      border: 2px solid $color-secondary;
      color: $color-secondary;
    }
    .text {
      color: $color-secondary;
    }
  }

  &:not([class*="ui-size-"]) {
    .icon {
      @include size( $circle-size );
      font-size: 24px;
      padding-left: 5px;
      padding-top: 2px;

      &::before {
        @include size( calc(#{$circle-size} + 20px) );
      }

      &::after {
        @include size( calc(#{$circle-size} + 40px) );
      }
    }
    .text {
      font-size: 18px;
      padding-left: $gutter;
      letter-spacing: -1px;
    }
  }

  &.ui-size-small {
    .icon {
      @include size($circle-size-small);
      font-size: 18px;
      padding-left: 3px;
      padding-top: 1px;

      &::before {
        @include size( calc(#{$circle-size-small} + 20px) );
      }

      &::after {
        @include size( calc(#{$circle-size-small} + 40px) );
      }
    }
    .text {
      font-size: 14px;
      padding-left: 15px;
      letter-spacing: -0.3px;
    }
  }

  .icon {
    position: relative;
    z-index: 3;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 24px;

    &::before, &::after {
      content: "";
      position: absolute;
      @include pos-top-left(50%, 50%);
      z-index: -1;
      border-radius: 50%;
      opacity: 0;
      animation: pulsateVideoPlay 1.5s ease-out infinite;
    }

    &::before {
      border: 1px solid currentColor;
      opacity: .6;
    }

    &::after {
      border: 1px solid currentColor;
      opacity: .6;
    }
  }

  .text {
    flex: 1;
    line-height: 1.3;
    width: min-content;
    font-weight: 800;
  }
}