.breadcrumb {
  @include respond-to("max_tablet") {
    display: none;
  }

  ul {
    display: inline-flex;
  
    li {
      display: inline-flex;
      align-items: center;
      a {
        font-size: 14px;
        text-transform: capitalize;
        color: #fff;
        display: inline-flex;
        align-items: center;
        position: relative;
        transition: .3s;

        &::after {
          content: "";
          @include size(7px);
          border-top: 1px solid currentColor;
          border-right: 1px solid currentColor;
          transform: rotate(45deg);
          margin-left: 12px;
          margin-right: 17px;
        }

        &::before {
          content: "";
          position: absolute;
          @include pos-bottom(0, -1px, 0);
          background: currentColor;
          height: 1px;
          width: 0;
          transition: width .3s;
        }

        .icon {
          font-size: 18px;
        }

        &[href]:not(.not) {
          &:hover {
            color: $color-secondary;
            &:before {
              width: calc(100% - 35px);
            }
          }
        }
      }

      &:last-child {
        a:after {
          display: none;
        }
      }
    }
  }
}