.contact {
  &-map {
    background: #fff;
    padding: 20px;
    border-radius: $border-radius-xl;
    box-shadow: 0 5px 35px rgba($color-text, .1);
    margin-top: 30px;
    iframe {
      width: 100%;
      height: 550px;
      border-radius: $border-radius-xl;
      @include respond-to("max_tablet") {
        height: 350px;
      }
    }
  }

  &-item {
    background-color: #fff;
    padding: 20px;
    border-radius: $border-radius-xxl;
    box-shadow: 0 0 20px rgba($color-dark, .1);
    &:not(:last-child) {
      margin-bottom: 70px;
    }
    .form {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .section-header {
      .text {
        max-width: 600px;
        @include respond-to("max_mobile") {
          display: none;
        }
      }
    }
  }

  &-left {
    background-color: $color-primary;
    padding: 50px 40px;
    border-radius: $border-radius-xxl;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include respond-to("max_tablet") {
      margin-bottom: 20px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 200px;
      height: 200px;
      background-color: #fff;
      border-radius: 999px;
      right: -30px;
      bottom: -30px;
      z-index: -1;
      opacity: .1;
    }
  }

  &-social {
		display: flex;
		align-items: center;
		margin-top: 30px;
    @include respond-to("max_tablet") {
			justify-content: center;
		}
		.icon {
			font-size: 18px;
			color: #fff;
			background-color: rgba(#fff, .08);
			width: 40px;
			height: 40px;
			border-radius: 999px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .3s;
			&:not(:last-child) {
				margin-right: 10px;
			}
			&:hover {
				background-color: $color-secondary;
				color: #fff;
			}
		}
	}

  &-information {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    ul {
      li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        > a {
          display: inline-flex;
          align-items: center;
          color: #fff;
          transition: .3s;
          &:hover {
            color: $color-secondary;
          }
          .icon {
            font-size: 18px;
            width: 30px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            @include respond-to("max_tablet") {
              display: none;
            }
          }
    
          .text {
            line-height: 1.2;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.5px;
            flex: 1;
          }
        }
      }
    }
  }
}