.page-content {

  // -------------------------------------
  //   Page content header
  // -------------------------------------
  &__header {
    position: relative;
    display: flex;

    &:not(.ui-no-mb) {
      margin-bottom: 20px;
    }

    @include respond-to("min_laptop") {
      justify-content: space-between;
      align-items: center;
    }

    @include respond-to("max_laptop") {
      flex-direction: column;
    }

    &:not([class*="ui-color-"]) {
      .title {
        color: $color-primary;
      }
    }

    .title {
      font-size: 34px;
      letter-spacing: -0.5px;
      font-weight: 700;
      line-height: 1.3;
    }

    .date {
      display: inline-flex;
      align-items: center;
      color: $color-text-light;
      font-size: 14px;
      font-weight: 600;

      @include respond-to("min_laptop") {
        margin-left: 50px;
        margin-right: $gutter;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;  
      }

      @include respond-to("max_laptop") {
        margin-top: 10px;
      }

      .icon {
        font-size: 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: rgba($color-text, .3);
        border-radius: $border-radius-lg;
        @include size(35px);
        background-color: $bg;
        align-items: center;
        font-size: 16px;
      }

      .text {
        padding-left: 15px
      }
    }
  }

  // -------------------------------------
  //   Page content heading
  // -------------------------------------
  &__heading {
    font-weight: 700;

    &:not([class*="ui-txt-size-"]) {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &.ui-txt-size-small {
      font-size: 14px;
      margin-bottom: 5px;
    }

    &:not([class*="ui-color-"]) {
      color: $color-secondary;
    }

    &.ui-color-1 {
      color: $color-secondary;
    }
  }

  // -------------------------------------
  //   Page content photo
  // -------------------------------------
  &__photo {
    overflow: hidden;
    position: relative;
    border-radius: 30px;
    margin-bottom: 30px;

    @include respond-to("min_desktop") {
      width: 50%;  
    }
    
    @include respond-to("min_desktop") {
      &:not([class*="ui-width-"]) {
        margin-right: 30px;
        float: left;
      }
    }

    &.ui-width-full {
      width: 100%;
      height: 400px;
    }

    &.ui-right {
      @include respond-to("min_desktop") {
        float: right;
        margin-right: 0;
        margin-left: 30px
      }
    }

    img {
      @include size(100%);
      overflow: hidden;
      object-fit: cover;

      &:has( + .page-content__video ) {
        opacity: .75;
      }
    }
  }

  &__detail {
    > ul {
      > li {
        display: inline-block;
        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }
    }
  }

  // -------------------------------------
  //   Page content spot
  // -------------------------------------
  &__spot {
    margin-bottom: 25px;
   .title {
    font-size: 18px !important;
    color: $color-text;
    font-weight: 700 !important;
    letter-spacing: -0.5px;
   }

    &.ui-txt-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond-to("max_tablet") {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .date {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;

      @include respond-to("min_laptop") {
        margin-left: 50px;
        margin-right: $gutter;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;  
      }

      @include respond-to("max_laptop") {
        margin-top: 10px;
      }

      .icon {
        font-size: 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: rgba($color-secondary, 1);
        border-radius: $border-radius-lg;
        @include size(35px);
        background-color: rgba($color-secondary, .08);
        align-items: center;
        font-size: 16px;
      }

      .text {
        padding-left: 15px;
        color: $color-text-light;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  // -------------------------------------
  //   Page content description
  // -------------------------------------
  &__description {
    color: $color-text-light !important;
    font-size: 16px;
    line-height: 1.7;
    font-family: $font-primary !important;

    * {
      color: $color-text-light !important;
      font-family: $font-primary !important;
    }

    &.ui-txt-center {
      text-align: center;
    }
    
    a {
      color: #55acef;
      font-weight: 700;
      &:hover {
        text-decoration: underline;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      letter-spacing: -1px;
    }
    h5 {
      font-weight: 700;
      font-size: 14px;
    }

    > p {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    a {
      color: #55acef;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      font-weight: 800;
    }
    img {
      max-width: 100%;
    }
    ul, ol {
      padding-left: $gutter;
    }
    ul li {
      list-style: disc;
    }
    ol li { 
      list-style: decimal;
    }
    table {
      width: 100% !important;
      border: 1px solid rgba($color-text, .08) !important;
      tbody {
        tr {
          border-bottom: 1px solid rgba($color-text, .08) !important;
          td, th {
            padding: 10px $gutter !important;
            border-right: 1px solid rgba($color-text, .08) !important;
            line-height: 1.5;
            &:last-child {
              border-right: 0 none !important;
            }
          }
        }
      }
    }
  }

  // -------------------------------------
  //   Page content gallery
  // -------------------------------------
  &__gallery {
    display: inline-block;
    width: 100%;

    &:not(.ui-no-mt) {
      margin-top: 20px;
    }

    ul {
      row-gap: 20px;

      li {
        @include respond-to("max_mobile") {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include respond-to("mobile_l") {
          flex: 0 0 33.33%;
          max-width: 0 0 33.33%;
        }
      }
    }

    &-photo {
      width: 100%;
      height: 140px;
      border-radius: $border-radius-xl;
      overflow: hidden;
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .6s;
      }
    }
    
  }

  &__other {
    display: inline-block;
    width: 100%;

    .heading {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.5px;
      color: $color-primary;
    }
    
    &:not(.ui-no-mt) {
      margin-top: 30px;
    }
  }

    // -------------------------------------
  //   Page content cards
  // -------------------------------------
  &__cards {
    margin-top: 70px;
    margin-bottom: 30px;

    &.ui-contact {
      margin-top: 0;
      margin-bottom: 0;
      .card {
        height: 350px;
        padding-bottom: 50px;

        .text {
          height: 70px;
        }
      }
    }

    ul {
      li {
        @include respond-to("max_tablet") {
          margin-bottom: 30px;
        }
      }
    }

    .card {
      height: 410px;
      border-radius: $border-radius-xxl;
      box-shadow: 0 5px 35px rgba(black, .1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include padding-h(50px);

      @include respond-to("max_mobile") {
        @include padding-h(30px);
      }
      @include respond-to("mobile_l") {
        height: 250px !important;
      }
      @include respond-to("tablet") {
        @include padding-h(25px);
        height: 350px;

      }
      @include respond-to("desktop") {
        @include padding-h(30px);

      }

      &:hover {
        > .icon {
          transform: translateY(-10px);
          &::after {
            transform: translateY(10px) scale(.75);
          }
        }
      }

      .title {
        color: $color-dark;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
        @include respond-to("mobile_l") {
          font-size: 16px;
        }
        @include respond-to("tablet") {
          font-size: 16px;;
        }
      }
      > .icon {
        color: $color-primary;
        font-size: 72px;
        margin-top: 25px;
        margin-bottom: 50px;
        position: relative;
        transition: transform 0.35s,;
        transition-timing-function: cubic-bezier(.12,.845,.305,1);
        @include respond-to("mobile_l") {
          font-size: 54px;
          margin-bottom: 25px;
        }
        @include respond-to("tablet") {
          font-size: 54px;
        }
        &::after {
          position: absolute;
          height: 8px;
          width: 66px;
          content: "";
          bottom: -25px;
          left: 50%;
          margin-left: -33px;
          background: rgba(black, .06);
          z-index: -1;
          border-radius: 100%;
          transform: scale(1);
          transition: transform .35s;
          transition-timing-function: cubic-bezier(.12,.845,.305,1);
        }
      }
      .text {
        font-size: 16px;
        font-weight: 500;
        color: $color-text;
        line-height: 1.4;
        @include respond-to("mobile_l") {
          display: none;
        }
        @include respond-to("tablet") {
          font-size: 13px;
        }
      }
      .btn-wrapper {
        position: absolute;
        @include pos-bottom(0, 40px, 0);
        @include respond-to("mobile_l") {
          bottom: 25px;
        }
        a {
          color: $color-primary;
          font-size: 16px;
          font-weight: 600;
          display: inline-flex;
          align-items: center;
          position: relative;
          z-index: 1;

          @include respond-to("mobile_l") {
            font-size: 12px;
          }
          @include respond-to("tablet") {
            font-size: 13px;
          }

          &::after {
            content: "";
            position: absolute;
            @include pos-bottom(-10px, 0, -10px);
            background: $color-primary;
            z-index: -1;
            height: 0;
            transition: height .3s;
          }

          &:hover {
            color: #fff;
            &::after {
              height: 100%;
            }
          }

          .icon {
            font-size: 14px;
            margin-left: 10px;

            @include respond-to("mobile_l") {
              font-size: 12px;
            }
            @include respond-to("tablet") {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  // Düzey Sigorta code

  &__acente {
    padding-bottom: 70px;
    .acente {
      ul {
        row-gap: 30px;
        justify-content: center;
        li {
          @include respond-to("max_mobile") {
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      }

      &-cover {
        width: 100%;
        height: 90px;
        background-color: #fff;
        box-shadow: 0 0 20px rgba($color-dark, .1);
        border-radius: $border-radius-xl;
        padding: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &__login {
    box-shadow: 0 0 20px rgba($color-dark, .1);
    padding: 20px;
    border-radius: $border-radius-xxl;

    .login-cover {
      width: 100%;
      height: 400px;
      border-radius: $border-radius-xl;
      overflow: hidden;
      @include respond-to("max_tablet") {
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .form {
      padding: 20px;
      a {
        display: inline-block;
      }
      .title {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.5px;
        transition: .3s;
        &:hover {
          color: $color-secondary;
        }
      }
    }
  }
} 