.slide {
	position: relative;
}

// -------------------------------------
//   Slide height
// -------------------------------------
.slide, .slide-material, .hero {
	height: var(--slide-height);
}

// -------------------------------------
//   Slide content
// -------------------------------------
.slide {
	background-color: #f2f0fc;
  &-list {
    &:not(.ui-style-owl) {
			position: relative;
			@include pos-top-left(0, 0);
			li {
				display: none;
			}
		}
	}

  &-material {
    overflow: hidden;
		position: relative;
  	// img {
		// 	transition: transform 10s ease-in 0s;
		// }
		img, video {
  		@include size(100%);
			object-fit: cover;
  	}
	}

	&-photo {
		background-color: $color-dark;
		> div {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			height: 100%;
			width: 100%;
			opacity: .8;
		}
	}

  &-wrapper {
    position: absolute;
		@include pos-fixed;  	
		z-index: 1;
		overflow: hidden;
		&::before {
			content: "";
			position: absolute;
			left: -100px;
			bottom: -200px;
			width: 800px;
			height: 800px;
			z-index: -1;
			pointer-events: none;
			background-image: radial-gradient(closest-side, $color-primary, transparent );
			opacity: .2;
		}
		&::after {
			content: "";
			position: absolute;
			right: -100px;
			top: -200px;
			width: 900px;
			height: 900px;
			z-index: -1;
			pointer-events: none;
			background-image: radial-gradient(closest-side, $color-secondary, transparent );
			opacity: .2;
		}
	}
	
	&-container {
		height: 100%;
    display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: left;
		position: relative;
		@include respond-to("max_tablet") {
			text-align: center;
			justify-content: center;
			align-items: center;
		}
	}

  &-content {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include respond-to("max_tablet") {
			text-align: center;
			width: 100%;
		}
		
		.heading {
			color: #fff;
			font-weight: 700;
      line-height: 1.15;
			font-size: get-em(60px);
			letter-spacing: get-em(-0.6px);
			@include line-clamp(4);
			@include respond-to("max_laptop") {
				font-size: get-em(32px);
			}

			span {
				color: $color-secondary;
				font-weight: 800;
			}
		}

		> .text {
			color: rgba(#fff, 1);
			margin-top: $gutter;
			font-weight: 300;
			letter-spacing: -0.3px;
			font-size: get-em(18px);
			@include line-clamp(3);
			@include respond-to("max_laptop") {
				display: none;
			}
		}

	}

	&-buttons {
		margin-top: 30px;
		> a {
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}

	&-social {
		display: flex;
		align-items: center;
		margin-top: 30px;
		@include respond-to("max_tablet") {
			justify-content: center;
		}
		.icon {
			font-size: 18px;
			color: #fff;
			background-color: rgba(#fff, .3);
			width: 40px;
			height: 40px;
			border-radius: 999px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .3s;
			&:not(:last-child) {
				margin-right: 10px;
			}
			&:hover {
				background-color: $color-primary;
				color: #fff;
			}
		}
	}

	&-shape {
		width: 600px;
		position: absolute;
		right: -70px;
		bottom: 0;
		pointer-events: none;
		@include respond-to("max_tablet") {
			width: 300px;
			right: inherit;
			bottom: 30px;
		}
		@include respond-to("tablet") {
			width: 500px;
		}
		canvas {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&-right {
		position: absolute;
		right: 0;
		top: var(--header-height);
		bottom: 0;
		width: 48%;
		height: calc(100vh - #{var(--header-height)});
		z-index: 1;
		display: flex;
		align-items: center;
		@include respond-to("max_tablet") {
			width: 100%;
			top: inherit;
			height: 150px;
			bottom: 50px;
		}
		@include respond-to("tablet") {
			height: 550px;
		}
		&__boxes {
			position: relative;
			justify-content: center;
			row-gap: 20px;
			@include respond-to("max_tablet") {
				overflow-x: scroll;
				flex-wrap: nowrap;
				justify-content: flex-start;
				padding-bottom: 15px;
				margin-inline: 5px;
			}
			> div {
				@include respond-to("max_mobile") {
					flex: 0 0 50%;
					max-width: 50%;
				}
				@include respond-to("mobile_l") {
					flex: 0 0 33.33%;
					max-width: 33.33%;
				}
			}
			.box {
				background-color: #fff;
				box-shadow: 0 0 20px rgba($color-dark, .1);
				border-radius: $border-radius-lg;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				flex-direction: column;
				height: 150px;
				width: 100%;
				&:hover {
					.title {
						color: $color-secondary;
					}
				}
				.icon {
					font-size: 100px;
					@include respond-to("tablet") {
						font-size: 72px;
					}
					img {
						width: 1em;
						height: 1em;
						object-fit: contain;
					}
				}
				.title {
					font-size: 18px;
					font-weight: 700;
					letter-spacing: -0.5px;
					color: $color-dark;
					line-height: 1.3;
					@include line-clamp(1);
					transition: .3s;
					@include respond-to("tablet") {
						font-size: 14px;
					}
				}
			}
		}
	}

	&-bg {
		position: absolute;
		inset: 0;
		z-index: -1;
		.shape {
			position: absolute;
			right: 0;
			left: 0;
			top: var(--header-height);
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				filter: invert(1) brightness(0);
				opacity: .1;
			}
		}
		.shape-2 {
			position: absolute;
			left: -100px;
			bottom: -100px;
			border: 60px solid #fff;
			width: 450px;
			height: 450px;
			border-radius: 999px;
			opacity: .2;
		}
	}

	&-information {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 50%;
		@include respond-to("max_tablet") {
			display: none;
		}
		.information {
			position: absolute;
			display: flex;
			align-items: center;
			background-color: #fff;
			border-radius: $border-radius-lg;
			padding-left: 5px;
			padding-right: 15px;
			padding-block: 5px;
			&:nth-child(1) {
				left: -5vw;
				bottom: 8vw;
				box-shadow: 0 10px 20px rgba($color-primary, .1);
				animation: info 6s infinite running;
				@keyframes info {
					0% { transform: translateX(0); }
					50% { transform: translateX(20px); }
					100% { transform: translateX(0); }
				}
				.icon {
					background-color: $color-primary;
				}
			}
			&:nth-child(2) {
				left: 5vw;
				top: 10vw;
				box-shadow: 0 10px 20px rgba($color-secondary, .1);
				animation: info2 6s infinite running;
				@keyframes info2 {
					0% { transform: translateY(0); }
					50% { transform: translateY(20px); }
					100% { transform: translateY(0); }
				}
				.icon {
					background-color: $color-secondary;
				}
			}
			.icon {
				width: 45px;
				height: 45px;
				color: #fff;
				border-radius: $border-radius-lg;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 22px;
			}
			.title {
				flex: 1;
				padding-left: 10px;
				font-size: 16px;
				color: $color-dark;
				font-weight: 600;
			}
		}
	}

  &-dots {
		$dots-color: #fff;
		$dots-size: 12px;
		//
  	position: absolute;
  	@include pos-bottom(0, 30px, 0);
		@include margin-h(auto);
  	max-width: var(--container);
  	width: 100%;
  	z-index: 2;
		text-align: center;
		display: none;

  	li {
  		display: inline-block;
  		a {
				@include size($dots-size);
  			cursor: pointer;
  			display: inline-block;
				font-size: 0;
				position: relative;
				margin: 0 5px;
				border-radius: 20px;
				background: none;
				border: 2px solid #fff;
				transition: .3s;
			}
			
  		&.is-active {
				a {
					background: #fff;
					width: 100px;
				}
			}
			
  	}
  }

  &-arrows {
		position: absolute;
		z-index: 11;
		@include respond-to("min_tablet") {
			left: calc((100vw - #{var(--container)}) / 2);
			bottom: 40px;
		}
		@include respond-to("max_tablet") {
			left: 50%;
			transform: translateX(-50%);
			bottom: 50px;
			text-align: center;
		}

  	button {
			z-index: 2;
			color: #fff;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding-top: 2px;
			transition: .3s;
			@include respond-to("min_tablet") {
				@include size(60px);
				font-size: 28px;
				background-color: rgba($color-primary, .85);
			}
			@include respond-to("max_tablet") {
				@include size(40px);
				font-size: 16px;
				background-color: $color-primary;
			}
			
			&:hover {
				background-color: $color-secondary;
			}

			&:first-child {
				border-top-left-radius: $border-radius-xl;
				border-bottom-left-radius: $border-radius-xl;
				border-right: 0 none;
			}

			&:last-child {
				border-top-right-radius: $border-radius-xxl;
				border-bottom-right-radius: $border-radius-xxl;
			}

			&:not(:last-child) {
				margin-right: 1px;
			}

			svg {
				path {
					stroke-width: 2px !important;
				}
			}

			&.prev {
				padding-right: 2px;
			}

			&.next {
				padding-left: 2px;
			}
			
  	}
	}
	
}

// -------------------------------------
//   Slide effect controller
// -------------------------------------
.slide {

	&-list {
  	li {
			&.is-effect {
				// .slide-material img, 
				// .slide-photo > div {
				// 	transform: scale(1.15);
				// }
				
				.slide-content {
					.heading, .text, .small-title, .buttons-container {
						transform: translateX(0);
						opacity: 1;
						visibility: visible;	
					}
				}

				.slide-right {
					.ui-effect-1, .ui-effect-2, .ui-effect-3, .ui-effect-4, .ui-effect-5, .ui-effect-6, .ui-effect-7, .ui-effect-8 {
						transform: scale(1);
						opacity: 1;
						visibility: visible;	
					}
				}
				.slide-buttons {
					transform: translateX(0);
					opacity: 1;
					visibility: visible;	
				}
				.slide-social {
					transform: translateX(0);
					opacity: 1;
					visibility: visible;	
				}
			}
  	}

		.slide-buttons {
			opacity: 0;
			visibility: hidden;
			transform: translateX(-80px);
			transition: .9s .4s;
		}

		.slide-social {
			opacity: 0;
			visibility: hidden;
			transform: translateX(-80px);
			transition: .9s .5s;
		}

		.slide-content {
			.heading, > .text, .small-title, .buttons-container {
				opacity: 0;
				visibility: hidden;
			}
	
			.small-title {
				transform: translateX(-40px);
			}
			.heading {
				transform: translateX(-60px);
			}
			> .text {
				transform: translateX(-80px);
			}
			.buttons-container {
				transform: translateX(-100px);
			}
	
		}

		.slide-right {
			.ui-effect-1, .ui-effect-2, .ui-effect-3, .ui-effect-4, .ui-effect-5, .ui-effect-6, .ui-effect-7, .ui-effect-8 {
				opacity: 0;
				visibility: hidden;
			}
	
			.ui-effect-1 {
				transform: scale(0);
				transition: .2s .4s;
			}
			.ui-effect-2 {
				transform: scale(0);
				transition: .3s .4s;
			}
			.ui-effect-3 {
				transform: scale(0);
				transition: .4s .4s;
			}
			.ui-effect-4 {
				transform: scale(0);
				transition: .5s .4s;
			}
			.ui-effect-5 {
				transform: scale(0);
				transition: .6s .4s;
			}
			.ui-effect-6 {
				transform: scale(0);
				transition: .7s .4s;
			}
			.ui-effect-7 {
				transform: scale(0);
				transition: .8s .4s;
			}
			.ui-effect-8 {
				transform: scale(0);
				transition: .9s .4s;
			}
	
		}

		.slide-content {		
			.small-title {
				transition: .9s .1s;
			}
			.heading {
				transition: .9s .2s;
			}
			> .text {
				transition: .9s .3s;
			}
			.buttons-container {
				transition: .9s .4s;
			}
	
		}
	}

}