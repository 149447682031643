select.selectbox {
  @include field( rgba($color-text, .8) );
  appearance: none;
  cursor: pointer;
  position: relative;
  &:before {
    content: "";
  }

  &:focus {
    + .icon.arrow {
      transform: translateY(-50%) rotate(-90deg);
    }
  }
}