// -------------------------------------
//   Position Top
// ------------------------------------- 
@mixin pos-top( $top:0, $right:0, $left:0 ){
  top: $top;
  right: $right;
  left: $left;
};
@mixin pos-top-right( $top: 0, $right: 0 ) {
  top: $top;
  right: $right;
}
@mixin pos-top-left( $top: 0, $left: 0 ) {
  top: $top;
  left: $left;
}

// -------------------------------------
//   Position Bottom
// ------------------------------------- 
@mixin pos-bottom( $right:0, $bottom:0, $left:0 ) {
  right: $right;
  bottom: $bottom;
  left: $left;
};
@mixin pos-bottom-right( $right:0, $bottom:0 ) {
  right: $right;
  bottom: $bottom;
};
@mixin pos-bottom-left( $bottom:0, $left:0 ) {
  bottom: $bottom;
  left: $left;
};

// -------------------------------------
//   Position ( Right, Left )
// ------------------------------------- 
@mixin pos-fixed-right( $top: 0, $right: 0, $bottom: 0 ) {
  top: $top;
  right: $right;
  bottom: $bottom;
}
@mixin pos-fixed-left( $top: 0, $bottom: 0, $left: 0 ) {
  top: $top;
  bottom: $bottom;
  left: $left;
}

// -------------------------------------
//   Position Fixed
// -------------------------------------  
@mixin pos-fixed( $value: 0 ) {
  left: $value;
  right: $value;
  top: $value;
  bottom: $value;
};

// -------------------------------------
//   Gutter Vertical
// -------------------------------------  
@mixin margin-v( $val: 0 ) {
  margin-top: $val;
  margin-bottom: $val;
};
@mixin padding-v ( $val: 0 ) {
  padding-top: $val;
  padding-bottom: $val;
};

// -------------------------------------
//   Gutter Horizontal
// ------------------------------------- 
@mixin margin-h ( $val: 0 ) {
  margin-left: $val;
  margin-right: $val;
};
@mixin padding-h ( $val: 0 ) {
  padding-left: $val;
  padding-right: $val;
};

// -------------------------------------
//   Size
// ------------------------------------- 
@mixin size ( $val: 0 ) {
  width: $val;
  height: $val;
}

// -------------------------------------
//   Input placeholder
// ------------------------------------- 
@mixin input-placeholder($color) {
  &::-webkit-input-placeholder { /* Edge */
    color: $color;
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
  
  &::placeholder {
    color: $color;
  }
}

// -------------------------------------
//   Offscreen
// -------------------------------------
@mixin offscreen {
	position: absolute;
	left: -200%;
}

// -------------------------------------
//   Text ellipsis
// -------------------------------------
@mixin text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}

// -------------------------------------
//   Btn reset
// -------------------------------------
@mixin btn-reset {
	border-width: 0;
	padding: 0;
	background-color: transparent;
}

// -------------------------------------
//   Input reset
// -------------------------------------
@mixin input-reset {
	// number firefox
	appearance: textfield;

	// password
	&::-ms-reveal {
		display: none;
	}

	// number chrome
	&::-webkit-inner-spin-button {
		appearance: none;
	}

	// search
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-decoration,
	&::-webkit-search-results-decoration {
		appearance: none;
	}
}

// -------------------------------------
//   Semantically: Visible
// -------------------------------------
@mixin visually-hidden {
	position: absolute;
	clip-path: inset(100%);
	overflow: hidden;
	margin: -1px;
	border: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	white-space: nowrap;
	pointer-events: none;
	clip: rect(0 0 0 0);
}


// -------------------------------------
//   Field
// ------------------------------------- 
@mixin field( $color, $gutter-l: 25px, $gutter-r: 25px, $height: $field-height ) {
  width: 100%;
  height: $height;
  border: 2px solid rgba($color-text, .1);
  border-radius: $border-radius-md;
  font-family: inherit;
  padding-left: $gutter-l;
  padding-right: $gutter-r;
  color: $color;
  font-size: 15px;
  font-weight: 500;
  background: #fff;
  @include input-placeholder( $color );
}

// -------------------------------------
//   Field arrow
// ------------------------------------- 
@mixin field-arrow {
  position: absolute;
  pointer-events: none;
  @include pos-top-right(50%, 25px);
  transform: translateY(-50%);
  font-size: 14px;
  opacity: .5;
  transition: .3s;
}

// -------------------------------------
//   Triangle top
// -------------------------------------
@mixin triangle-top($size, $color) {
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color;
}

// -------------------------------------
//   Triangle bottom
// -------------------------------------
@mixin triangle-bottom($size, $color) {
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color;
}

// -------------------------------------
//   Line clamp
// -------------------------------------
@mixin line-clamp($line, $height: auto) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  max-height: $height;
}

// -------------------------------------
//   Scrollbar
// -------------------------------------
@mixin scrollbar( $color, $opacity ) {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: $color rgba($color, $opacity);
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: rgba($color, $opacity);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
  }
}
