.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.hinge {
  animation-duration: 2s;
}

// -------------------------------------
//   Fade
// -------------------------------------
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

// -------------------------------------
//   Bounce
// -------------------------------------
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation-name: bounce;
}

// -------------------------------------
//   Swing
// -------------------------------------
@keyframes swing {
	0% {
	 	transform:translate3d(0, 0, 0);
	}
	50% {
	 	transform:translate3d(0, -30px ,0);
	}
	to {
	 	transform:translate3d(0, 10px ,0);
	}
}

// -------------------------------------
//   Zomm
// -------------------------------------
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

// -------------------------------------
//   pulse
// -------------------------------------
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

// -------------------------------------
//   Pulsate
// -------------------------------------
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

// -------------------------------------
//   Pulsate Video Play
// -------------------------------------
@keyframes pulsateVideoPlay {
  0% {
    transform: translateY(-50%) translateX(-50%) scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(-50%) translateX(-50%) scale(1.2, 1.2);
    opacity: 0;
  }
}

// -------------------------------------
//   Spin
// -------------------------------------
@keyframes spin {
  0% { transform: rotate(0deg); }
  
  100% { transform: rotate(360deg); }
}
