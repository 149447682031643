.button-fixed {
  position: fixed;
  text-align: center;
  z-index: 999;
	transition: .3s;

	&:not([class *= "fixed-social"]) {
		display: inline-flex;
		justify-content: center;
		height: 60px;
		width: 60px;
		bottom: 10px;
	}

	canvas {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.fixed-social {
		display: flex;
		flex-direction: column;
		align-items: center;
		bottom: 30px;
		right: 22px;
		height: 50px;
		overflow: hidden;
		transition: .4s;
		@include respond-to("max_tablet") {
			right: 15px;
			bottom: 40px;
		}
		&:hover {
			height: 325px;
			.icons {
				opacity: 1;
				transform: translateY(0);
				visibility: visible;
			}
		}
		.fixed-social__button {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
		}
		.icons {
			display: flex;
			flex-direction: column;
			align-items: center;
			opacity: 0;
			transform: translateY(20px);
			visibility: hidden;
			transition: .4s;
		}
		.icon {
			width: 50px;
			height: 50px;
			background-color: $color-primary;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: $border-radius-lg;
			color: #fff;
			font-size: 22px;
			&:not(:last-child) {
				margin-bottom: 5px;
			}
			&:nth-child(1) {
				background: #4267B2;
			}
			&:nth-child(2) {
				background: transparent linear-gradient(41deg, #FFDC80 0%, #FCAF45 12%, #F77737 26%, #F56040 40%, #FD1D1D 51%, #E1306C 69%, #833AB4 82%, #405DE6 100%) 0% 0% no-repeat padding-box;
			}
			&:nth-child(3) {
				background: #1d9bf0;
			}
			&:nth-child(4) {
				background: #FF0000;
			}
			&:nth-child(5) {
				background: #0277b0;
			}
		}
	}

	&.ui-wp {
		width: 80px;
		height: 80px;
		right: 8px;
		@include respond-to("max_tablet") {
			right: 0;
			margin-bottom: 10px;
		}
	}

	@include respond-to("min_tablet") {
		right: 20px;
	}

	@include respond-to("max_tablet") {
		right: 12px;
	}

	.icon {
		@include respond-to("min_tablet") {
			font-size: 28px;
		}
		@include respond-to("max_tablet") {
			font-size: 22px;
		}
	}

	&.is-active {
		@include respond-to("min_tablet") {
			transform: translateY(-70px);
		}
		@include respond-to("max_tablet") {
			transform: translateY(-60px);
		}
	}
}