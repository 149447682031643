.inner-page {

  // -------------------------------------
  //   Inner page header
  // -------------------------------------
  &__header {
    background-color: $color-primary;
    &:not([class*="ui-pos-"]) {
      position: relative;
    }

    &.ui-pos-absolute {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: -1;
    }
  }

  // -------------------------------------
  //   Inner page photo
  // -------------------------------------
  &__photo {    
    &:not([class*="ui-height-"]) {
      height: 400px;
      @include respond-to("max_tablet") {
        height: 350px;
      }
    }

    &.ui-height-medium {
      height: 400px
    }

    &.ui-height-large {
      height: 750px
    }

    img {
      @include size(100%);
      object-fit: cover;
      opacity: .5;
    }
  }

  // -------------------------------------
  //   Inner page heading
  // -------------------------------------
  &__heading {
    z-index: 3;
    position: relative;
    height: 270px;

    &.ui-detail-offer {
      .container {
        justify-content: flex-start;
        padding-top: 30px;
      }
    }

    &.ui-detail-login {
      height: 120px;
    }

    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      z-index: 1;
      overflow: hidden;
    }


    &-photo {
      height: 300px;
      border-radius: $border-radius-xl;
      overflow: hidden;

      img {
        @include size(100%);
        object-fit: cover;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .logo {
        a {
          display: block;
          position: relative;
        }

        img {
          width: 150px;
          filter: brightness(0) invert(1);
        }
      }

      .title {
        color: #fff;
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 1.1;
        margin-bottom: 10px;
        @include respond-to("max_tablet") {
          font-size: 36px;
        }

        &.ui-size-small {
          font-size: 24px;
          margin-top: 30px;
          letter-spacing: -0.5px;
          @include respond-to("max_mobile") {
            font-size: 16px;
            font-weight: 600;
          }
        }

        span {
          color: $color-primary;
        }
      }
  
      .text {
        color: #fff;
        font-size: 15px;
        line-height: 1.6;
        margin-top: 5px;
        max-width: 500px;
        margin-bottom: 10px;
        strong {
          font-weight: 800;
        }
      }
    }

    &-social  {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 40px;
      @include respond-to("max_tablet") {
        left: 50%;
        transform: translateX(-50%);
      }
      .icon {
        font-size: 16px;
        color: #fff;
        width: 40px;
        height: 40px;
        border-radius: 999px;
        border: 1px solid rgba(#fff, .2);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        &:not(:last-child) {
          margin-right: 10px;
        }
        &:hover {
          background-color: #fff;
          color: $color-primary;
        }
      }
    }

  }
}