.modal-menu {
  position: fixed;
  inset: 0;
  z-index: 2223;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  backdrop-filter: blur(3px);
  background-color: rgba($color-primary, .55);
  transition: opacity .4s;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-current {
    opacity: 1;
    visibility: visible;
    pointer-events: inherit;
    .modal-menu__wrapper {
      transform: translateY(0) translateX(-50%);
      opacity: 1;
      visibility: visible;
    }
  }

  &__wrapper {
    border-radius: $border-radius-xxl;
    overflow: hidden;
    padding: 50px;
    transform: translateY(100px) translateX(-50%);
    transition: .8s .1s;
    background-color: #fff;
    position: absolute;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    max-width: 500px;
    width: 100%;
    max-height: 660px;
    overflow-y: auto;
    @include respond-to("max_tablet") {
      padding: 30px;
      padding-top: 20px;
    }
    @include respond-to("laptop") {
      padding: 30px;
    }
  }

  &__close {
    font-size: 28px;
    color: #000;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    background-color: $color-secondary;
    color: #fff;
    @include respond-to("max_tablet") {
      font-size: 22px;
      top: 15px;
      right: 25px;
    }
  }

  .modal-policy {
    .title {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.5px;
      color: $color-dark;
      margin-bottom: 20px;
    }
    ul {
      margin-bottom: 30px;
      li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          border-bottom: 1px solid rgba($color-dark, .1);
          margin-bottom: 10px;
          padding-bottom: 10px;
        }
        .text {
          font-size: 16px;
          flex: 0 0 40%;
          max-width: 40%;
        }
        .title {
          margin-bottom: 0;
        }
      } 
    }
  }
}