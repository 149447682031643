html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  @include padding-h( calc( #{$gutter} / 2 ) );
  @include margin-h( auto );
}

@include respond-to("max_mobile") {
  .container {
    max-width: 390px;
  }
}

@include respond-to("min_mobile") {
  .container {
    max-width: 600px;
  }
}

@include respond-to("min_tablet") {
  .container {
    max-width: 768px;
  }
}

@include respond-to("min_desktop") {
  .container {
    max-width: 992px;
  }
}

@include respond-to("min_laptop") {
  .container {
    max-width: var(--container);
  }
}

.container-fluid {
  width: 100%;
  @include padding-h( calc( #{$gutter} / 2 ) );
  @include margin-h(auto);

  @include respond-to("min_laptop(1400)") {
    @include padding-h( $gutter-fluid );
  }

  @include respond-to("min_large") {
    @include padding-h( $gutter-fluid-large );
  }

  @include respond-to("min_large(2020)") {
    max-width: 2000px;
  }

  @include respond-to("desktop") {
    max-width: 992px;
  }

  @include respond-to("tablet") {
    max-width: 768px;
  }

  @include respond-to("mobile_l") {
    max-width: 600px;
  }

  @include respond-to("max_mobile") {
    max-width: 390px;
  }

}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  @include margin-h( calc( -1 * ( #{$gutter} / 2 ) ) );
}

.no-gutters {
  @include margin-h(0);
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  @include padding-h(0);
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  @include padding-h( calc( #{$gutter} / 2 ) );
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@include respond-to("min_mobile") {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include respond-to("min_tablet") {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include respond-to("min_desktop") {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include respond-to("min_laptop") {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.ui-d-none {
  display: none !important;
}

.ui-d-inline-block {
  display: inline-block !important;
}

.ui-d-block {
  display: block !important;
}

.ui-d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.ui-d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@include respond-to("max_mobile") {
  .ui-d-sm-none {
    display: none !important;
  }
  .ui-sm-d-inline-block {
    display: inline-block !important;
  }
  .ui-sm-d-block {
    display: block !important;
  }
  .ui-sm-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .ui-sm-d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include respond-to("max_tablet") {
  .ui-md-d-none {
    display: none !important;
  }
  .ui-md-d-inline-block {
    display: inline-block !important;
  }
  .ui-md-d-block {
    display: block !important;
  }
  .ui-md-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .ui-md-d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include respond-to("max_desktop") {
  .ui-lg-d-none {
    display: none !important;
  }
  .ui-lg-d-inline-block {
    display: inline-block !important;
  }
  .ui-lg-d-block {
    display: block !important;
  }
  .ui-lg-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .ui-lg-d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include respond-to("max_laptop") {
  .ui-xl-d-none {
    display: none !important;
  }
  .ui-xl-d-inline-block {
    display: inline-block !important;
  }
  .ui-xl-d-block {
    display: block !important;
  }
  .ui-xl-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .ui-xl-d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include respond-to("print") {
  .ui-print-d-none {
    display: none !important;
  }
  .ui-print-d-inline-block {
    display: inline-block !important;
  }
  .ui-print-d-block {
    display: block !important;
  }
  .ui-print-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .ui-print-d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

// -------------------------------------
//   Extra
// ------------------------------------- 
@include respond-to("min_laptop") {
  // -------------------------------------
  //   Gutter default
  // ------------------------------------- 
  .ui-gutter {
    @include margin-h(-1 * $gutter);
    row-gap: $gutter
  }

  .ui-gutter > .col,
  .ui-gutter > [class*="col-"] {
    @include padding-h( $gutter );
  }

  // -------------------------------------
  //   Gutter 15
  // -------------------------------------
  .ui-gutter-15 {
    @include margin-h(-1 * $gutter-15);
    row-gap: $gutter-15;
  }

  .ui-gutter-15 > .col,
  .ui-gutter-15 > [class*="col-"] {
    @include padding-h( $gutter-15 );
  }

  // -------------------------------------
  //   Gutter 30
  // -------------------------------------
  .ui-gutter-30 {
    @include margin-h(-1 * $gutter-30);
    row-gap: $gutter-30;
  }

  .ui-gutter-30 > .col,
  .ui-gutter-30 > [class*="col-"] {
    @include padding-h( $gutter-30 );
  }

  // -------------------------------------
  //   Gutter large
  // -------------------------------------
  .ui-gutter-large {
    @include margin-h( -1 * $gutter-large );
    row-gap: $gutter-large;
  }

  .ui-gutter-large > .col,
  .ui-gutter-large > [class*="col-"] {
    @include padding-h( $gutter-large );
  }

}

// -------------------------------------
//   Gutter 5
// -------------------------------------
.ui-gutter-5 {
  @include margin-h(-1 * $gutter-5 !important);
  row-gap: $gutter-5;
}

.ui-gutter-5 > .col,
.ui-gutter-5 > [class*="col-"] {
  @include padding-h( $gutter-5 !important );
}

// -------------------------------------
//   Gutter 1
// -------------------------------------
.ui-gutter-1 {
  @include margin-h(-1 * 1px !important );
  row-gap: 1px;
}

.ui-gutter-1 > .col,
.ui-gutter-1 > [class*="col-"] {
  @include padding-h( 1px !important );
}