.navigation {
  display: flex;
  @include respond-to("max_desktop") {
    display: none
  }

  @include margin-h( -1 * $nav-gutter );

  &-item {
    $triangle-size: 11px;

    @include padding-h( $nav-gutter );

    &.is-triangle {
      > a, > button {
        &::after {
          display: block;
        }
      }
    }

    //
    > a, > button {
      display: inline-flex;
      align-items: center;
      color: $color-text-light;
      font-size: 16px;
      letter-spacing: -.1px;
      font-weight: 800;
      height: 90px;
      font-family: inherit;
      line-height: 1.2;
      transition: .3s;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        @include pos-bottom(0, -10px, 0);
        height: 0;
        width: 20px;
        border-radius: 999px;
        @include margin-h(auto);
        background-color: $color-primary;
        transition: height .3s;
      }

    }

    // Hover
    &:hover {
      > a, > button {
        color: $color-primary;
        &::before {
          height: 20px;
        }
        &::after {
          opacity: 1;
          visibility: visible;
          transition: .3s;
        }
      }

      > .navigation-links {
        opacity: 1;
        visibility: visible;
      }

      .navigation-dropdown {
        opacity: 1;
        visibility: visible;
      }

      .navigation-dropdown__content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: .3s;
      }

    }
  }

  &-links {
    position: absolute;
    background: #fff;
    border-radius: $border-radius-lg;
    box-shadow: 0 20px 40px rgba($color-text, .1);
    min-width: 230px;
    @include padding-v(10px);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    //
    > li {
      display: block;
      > a {
        display: block;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.5px;
        padding: 12px 30px;
        color: $color-text;
        transition: .3s;
        &:hover {
          color: #fff;
          background: $color-primary;
        }
      }
    }
  }

  &-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 1;
    min-height: 450px;
    opacity: 0;
    visibility: hidden;

    &::before {
      content: "";
      position: absolute;
      @include pos-fixed;
      z-index: -1;
      background: rgba($bg, .7);
    }

    &__content {
      @include padding-v(50px);
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
    }
  }

}