@use 'sass:math';
/* ____________________________________________ */
/* ________GET-VW__________ */
/* ____________________________________________ */
@function get-vw($pixel-value, $viewport-width: 1250) {
  $vw-context: ($viewport-width * 0.01) * 1px;
  $result: math.div($pixel-value, $vw-context);
  @return #{$result}vw;
}

/* ____________________________________________ */
/* ________GET-VH__________ */
/* ____________________________________________ */
@function get-vh($pixel-value, $viewport-height: 768) {
  $vh-context: ($viewport-height * 0.01) * 1px;
  $result: math.div($pixel-value, $vh-context);
  @return #{$result}vh;
}

/* ____________________________________________ */
/* ________GET-EM__________ */
/* ____________________________________________ */
@function get-em($pixel-value, $base-font-size: 16) {
  $em-context: $base-font-size * 1px;
  $result: math.div($pixel-value, $em-context);
  @return #{$result}em;
}

/* ____________________________________________ */
/* ________GET-REM__________ */
/* ____________________________________________ */
@function get-rem($pixel-value, $root-font-size: 16) {
  $rem-context: $root-font-size * 1px;
  $result: math.div($pixel-value, $rem-context);
  @return #{$result}rem;
}
