.branches {

  ul {
    row-gap: 40px;
    justify-content: center;
  }

  &-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba($color-dark, .15);
    border-radius: $border-radius-xl;
    padding: 20px;
    height: 210px;
    &:hover {
      .branches-icon {
        &::before {
          transform: scale(0);
          opacity: 0;
        }
      }
    }
  }

  &-icon {
    width: 70px;
    height: 70px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    color: #fff;
    font-size: 26px;
    position: relative;
    top: -40px;
    box-shadow: 0 0 20px rgba($color-dark, .2);
    z-index: 1;
    
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: $color-secondary;
      z-index: -1;
      transition: .4s;
      border-radius: 999px;
      opacity: 1;
    }
  }

  &-information {
    margin-top: -15px;
    .title {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: -0.8px;
      line-height: 1.3;
      color: $color-text;
      text-transform: uppercase;
    }
    .address {
      font-size: 14px;
      color: $color-text-light;
      line-height: 1.4;
      margin-block: 15px;
    }
  }

  &-contact {
    display: flex;
    align-items: center;
    > a {
      display: inline-flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 15px;
      }
      &:hover {
        .text {
          color: $color-secondary;
        }
      }
      .icon {
        font-size: 18px;
        color: $color-secondary;
        @include respond-to("max_tablet") {
          font-size: 16px;
        }
      }
      .text {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.3px;
        color: $color-text;
        flex: 1;
        white-space: nowrap;
        padding-left: 10px;
        transition: .3s;
        @include respond-to("max_tablet") {
          font-size: 12px;
        }
      }
    }
  }
  
}