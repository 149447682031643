.section {

  &-products {
    padding-top: 70px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: -50px;
      height: 1500px;
      background-image: url("../images/shapes/2.png");
      background-position: right top;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: .5;
      z-index: -1;
      pointer-events: none;
    }
  }

  &-about {
    padding-block: 100px;
    @include respond-to("max_tablet") {
			padding-block: 70px;
		}
  }

  &-parallax {
    .container-fluid {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: -5px;
        bottom: 0;
        background-image: url("../images/shapes/9.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 130px;
        height: 130px;
        z-index: 1;
        animation: cube 4s infinite running;
        @keyframes cube {
          0% { transform: translateY(0) rotate(0); }
          25% { transform: translateY(-30px) rotate(0); }
          50% { transform: translateY(0) rotate(0); }
          75% { transform: translateY(0) rotate(180deg); }
          100% { transform: translateY(0) rotate(0); }
        }
      }
    }
  }

  &-how {
    padding-top: 70px;
  }

  &-blog {
    padding-top: 70px;
  }

  &-referances {
    padding-top: 70px;
  }

  &-offer {
    padding-top: 100px;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
      background-image: url("../images/offer-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
      @include respond-to("max_tablet") {
        display: none;
      }
    }
    .section-header {
      .title {
        line-height: 1.4;
      }
      .text {
        max-width: 600px;
      }
    }
  }

}