label.checkbox {
  display: inline-flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  &.is-error {
    .checkbox {
      border: 2px solid red !important;
    }
    .text {
      color: red !important;
      
      a {
        color: red !important;
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
  }

  .checkbox {
    display: inline-flex;
    @include size(22px);
    background: none;
    border: 2px solid rgba($color-text, .1);
    border-radius: $border-radius-md;
    transition: .2s;
    position: relative;
    &:before {
      content: "✓";
      position: absolute;
      top: -3px;
      line-height: 0.7;
      font-size: 24px;
      transform: scale(0);
      color: $color-primary;
      opacity: 0;
      visibility: hidden;
      transition: .2s;
    }
  }

  .text {
    color: $color-text-light;
    flex: 1;
    font-size: 13px;
    line-height: 1.3;
    padding-left: 10px;
    span {
      opacity: .5;
      margin-left: 5px;
    }
    strong {
      font-weight: 900;
    }
    a {
      font-weight: 900;
      color: $color-primary;
    }
  }
  
  input:checked + .checkbox:before {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}