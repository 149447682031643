@mixin respond-to( $breakpoint ) {

  @if( $breakpoint == "max_mobile" ){
    @media ( max-width: 479px ) { @content }
  }

  @else if ( $breakpoint == "mobile_xs" ) {
    @media ( min-width: 390px ) and ( max-width: 479px ) { @content }
  }

  @else if ( $breakpoint == "min_mobile" ) {
    @media ( min-width: 480px ) { @content }
  }

  @else if ( $breakpoint == "max_tablet" ) {
    @media ( max-width: 767px ) { @content }
  }

  @else if ( $breakpoint == "mobile_l" ) {
    @media ( min-width: 480px ) and ( max-width: 767px ) { @content }
  }

  @else if ( $breakpoint == "mobile_xl" ) {
    @media ( min-width: 576px ) and ( max-width: 767px ) { @content }
  }

  @else if ( $breakpoint == "tablet" ) {
    @media ( min-width: 768px ) and ( max-width: 991px ) { @content }
  }

  @else if ( $breakpoint == "min_tablet" ) {
    @media ( min-width: 768px ) { @content }
  }

  @else if ( $breakpoint == "max_desktop" ) {
    @media ( max-width: 991px ) { @content }
  }
  
  @else if ( $breakpoint == "desktop" ) {
    @media ( min-width: 992px ) and ( max-width: 1199px ) { @content }
  }

  @else if ( $breakpoint == "laptop" ) {
    @media ( min-width: 1200px ) and ( max-width: 1450px ) { @content }
  }

  @else if ( $breakpoint == "min_desktop" ) {
    @media ( min-width: 992px ) { @content }
  }

  @else if ( $breakpoint == "min_laptop" ) {
    @media ( min-width: 1200px ) { @content }
  }

  @else if ( $breakpoint == "min_laptop(1400)" ) {
    @media ( min-width: 1400px ) { @content }
  }

  @else if ( $breakpoint == "min_laptop(1450)" ) {
    @media ( min-width: 1450px ) { @content }
  }

  @else if ( $breakpoint == "min_laptop(1500)" ) {
    @media ( min-width: 1500px ) { @content }
  }

  @else if ( $breakpoint == "max_laptop" ) {
    @media ( max-width: 1199px ) { @content }
  }

  @else if ( $breakpoint == "max_laptop(1450)" ) {
    @media ( max-width: 1450px ) { @content }
  }

  @else if ( $breakpoint == "max_laptop(1400)" ) {
    @media ( max-width: 1400px ) { @content }
  }

  @else if ( $breakpoint == "min_large" ) {
     @media ( min-width: 1750px ) { @content }
  }

  @else if ( $breakpoint == "min_large(2020)" ) {
    @media ( min-width: 2020px ) { @content }
 }

  @else if ( $breakpoint == "print" ) {
    @media print { @content }
  }
  
};