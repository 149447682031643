.offer {
  text-align: center;

  &-cover {
    width: 100%;
    height: 200px;
    @include respond-to("max_tablet") {
			display: none;
		}
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}